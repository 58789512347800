import React, { Component } from 'react'

import { useTranslation } from 'react-i18next'
import { keys, isObject } from 'lodash'

import { Modal, Button, Form, Input, Slider } from 'antd'

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24,
	},
}

export const StakefactorModal = (props: any) => {
	const [form] = Form.useForm()
	const { t } = useTranslation()

	const handleFormSubmit = () => {
		console.log('handleFormSubmit')
		form.validateFields()
			.then((values) => {
				console.log('validated', values)
				props.onSave(values)
				props.onHide()
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo)
			})
	}

	const footer = (
		<div style={{ display: 'flex' }}>
			<Button
				onClick={() => {
					props.onDelete(props)
				}}
				danger={true}
				style={{ marginRight: 'auto' }}
			>
				Delete
			</Button>
			<Button
				onClick={() => {
					props.onHide()
				}}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	)

	return (
		<Modal
			open={true}
			title={props.id ? 'Stakeholder edition' : 'New stakeholder'}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
		>
			<Form
				{...formLayout}
				form={form}
				onFinish={(values) => {
					props.onChange(props.id, values)
					props.onHide()
				}}
				initialValues={props.values}
			>
				<Form.Item name="name" label="Name">
					<Input></Input>
				</Form.Item>

				<Form.Item name="description" label={t('models.item.fields.description')}>
					<Input.TextArea rows={7}></Input.TextArea>
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default StakefactorModal
