import React, { Component } from 'react'

import { uniq } from 'lodash'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons'

import { Input, Tag } from 'antd'

export const TagsTab = (props: any) => {
	const { t } = useTranslation()

	const [enteredTags, setEnteredTags] = React.useState<any | null>('')

	let unassignedTags = []
	if (props.selection) {
		unassignedTags = props.allTags
			.filter((t: string) => !(props.selection.tags || []).includes(t))
			.map((t: string, index: number) => (
				<Tag
					onClick={() => {
						props.onChangeItemTags(props.selection.id, uniq([...(props.selection.tags || []), t]))
					}}
					color={'#FFFFFF00'}
					style={{ color: '#FFFFFF', border: '0.5px solid #FFFFFF' }}
					icon={
						<FontAwesomeIcon
							style={{ marginRight: '5px', marginBottom: '3px', color: '#FFFFFF' }}
							icon={faPlusCircle}
						></FontAwesomeIcon>
					}
					className="tag"
				>
					{t}
				</Tag>
			))
	}

	return (
		<>
			<div className="d-flex flex-column flex-grow mt-3">
				{!props.scenario && (
					<div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
						<Input
							key={props.selection ? props.selection.id : 'empty'}
							onChange={(event) => {
								setEnteredTags(event.target.value ? event.target.value.slice(0, 30) : '')
							}}
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									let newTags = enteredTags.trim().split(',')
									props.onChangeItemTags(props.selection.id, [
										...(props.selection.tags || []),
										...newTags,
									])
									setEnteredTags('')
								}
							}}
							value={enteredTags}
							placeholder="Create new tags"
						></Input>
					</div>
				)}

				<div className="text-left mb-2">{t('pages.navigator.tabs.tags.assigned')}</div>
				<div className="text-left">
					{(props.selection.tags || []).length > 0 ? (
						(props.selection.tags || []).map((t, index) => (
							<Tag
								key={index}
								onClick={() => {
									props.onChangeItemTags(
										props.selection.id,
										(props.selection.tags || []).filter((st) => st != t)
									)
								}}
								color={'#FFFFFF'}
								style={{ color: '#555555' }}
								icon={
									<FontAwesomeIcon
										style={{ marginRight: '5px', marginBottom: '3px', color: '#555555' }}
										icon={faMinusCircle}
									></FontAwesomeIcon>
								}
								className="tag"
							>
								{t}
							</Tag>
						))
					) : (
						<div style={{ color: 'rgba(46, 160, 223, 0.3)' }}>{t('pages.navigator.no_tag')}</div>
					)}
				</div>

				<div className="text-left mt-4 mb-2">{t('pages.navigator.tabs.tags.unassigned')}</div>

				<div className="text-left">
					{unassignedTags.length > 0 ? (
						unassignedTags
					) : (
						<div style={{ color: 'rgba(46, 160, 223, 0.3)' }}>{t('pages.navigator.no_tag')}</div>
					)}
				</div>
			</div>
		</>
	)
}

export default TagsTab
