import { Any } from '@@react-spring/web/types'
import React from 'react'
import { ImpulseSpinner } from 'react-spinners-kit'

function Loader(props: Any) {
	return (
		<div
			style={{ minHeight: '300px' }}
			className="Loader w-100 d-flex justify-content-center align-items-center flex-grow-1"
		>
			<ImpulseSpinner size={props.size || 60} frontColor={props.color || '#2EA0DF'}></ImpulseSpinner>
		</div>
	)
}

export default Loader
