import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import {
	useDeleteProjectMutation,
	useListProjectsQuery,
	ListProjectsDocument,
	useOnCreateProjectSubscription,
	useCloneProjectMutation,
} from '../../graphql/generated'

import ProjectItem from './ProjectItem'
import Loader from 'components/Loading/Loader'
import { ConfirmModal } from 'components/Modal/ConfirmModal'

export const ProjectsList = (props: any) => {
	const [lastAddition, setLastAddition] = React.useState<string>('')
	const { data, refetch, loading } = useListProjectsQuery({
		variables: { u: localStorage.getItem('u') },
	})
	const { t } = useTranslation()
	const _refetch = useCallback(() => {
		setTimeout(() => refetch(), 0)
	}, [refetch])

	const [deleteData] = useDeleteProjectMutation()
	const [cloneData] = useCloneProjectMutation()
	const projectSubscription = useOnCreateProjectSubscription()

	/*if (projectSubscription.data && refetch && projectSubscription.data.id && projectSubscription.data.id!=lastAddition) {
    console.log("refetch");
    //setLastAddition(projectSubscription.data.id);
    refetch();
  }*/

	//console.log(projectSubscription);

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.project.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDelete(id)}
				message={t('models.project.messages.delete_confirmation_explanation')}
			></ConfirmModal>
		)
	}

	const askCloneConfirmation = async (id?: string) => {
		if (id == null) {
			return
		}

		props.pushModal(
			<ConfirmModal
				title={t('models.project.messages.clone_confirmation')}
				t={t}
				onConfirm={() => handleClone(id)}
				message={t('models.project.messages.clone_confirmation_explanation')}
			></ConfirmModal>
		)
	}

	const handleClone = async (id?: string) => {
		if (id == null) {
			return
		}

		await cloneData({
			variables: { id },
		})

		await _refetch()
	}

	const handleDelete = async (id?: string) => {
		console.log('deleteData', id)

		if (id == null) {
			return
		}

		await deleteData({
			variables: { id },
			/*update: async (store) => {
				let data: any = await store.readQuery({
					query: ListProjectsDocument,
				})

				console.log('handleDelete data', data)

				store.writeQuery({
					query: ListProjectsDocument,
					data: {
						listProjects: {
							...data.listProjects,
							items: data.listProjects.items.filter((o: any) => o.id !== id),
						},
					},
				})
			},*/
		})

		await _refetch()
	}

	return (
		<>
			{loading ? (
				<Loader></Loader>
			) : (
				data?.listProjects?.items?.map((value: any, index: Number) =>
					index == 0 && props.tutorialPopover ? (
						React.cloneElement(props.tutorialPopover, {
							key: value?.id,
							children: (
								<ProjectItem
									onDelete={() => {
										askDeleteConfirmation(value?.id)
									}}
									onClick={props.onSelectProject}
									onClone={
										props.canClone
											? (e: any) => {
													console.log('onClone', value?.id, e)
													askCloneConfirmation(value?.id)
													return false
											  }
											: null
									}
									key={value?.id}
									details={value}
								></ProjectItem>
							),
						})
					) : (
						<ProjectItem
							onDelete={() => {
								askDeleteConfirmation(value?.id)
							}}
							onClick={props.onSelectProject}
							onClone={
								props.canClone
									? (e: any) => {
											console.log('onClone', value?.id, e)
											askCloneConfirmation(value?.id)
											return false
									  }
									: null
							}
							key={value?.id}
							details={value}
						></ProjectItem>
					)
				)
			)}
		</>
	)
}
