import { sumBy, forEach, keyBy, memoize } from 'lodash'

import {
	radarHighToAverageBoundariesRatio,
	radarAverageToLowBoundariesRatio,
	radarLikelyToUndefinedBoundariesRatio,
} from 'components/Radar/MainRadar'
import { getItemAlteredByScenario, vulnerabilityConstraints, vulnerabilityIndicators } from './rules'

export const getScorePart = (option: any, items: any, evaluations: any) => {
	//console.log('getScorePart', option, items, evaluations);
	let indexedEvaluations = keyBy(evaluations, 'itemId') || {}

	let total = 0
	let count = 0
	forEach(items, (s) => {
		let weight = s.weight || 1
		count += weight
		total +=
			weight *
			(indexedEvaluations && indexedEvaluations[s.id] && indexedEvaluations[s.id].value !== undefined
				? indexedEvaluations[s.id].value
				: 50)
	})

	return count != 0 ? total / count : 0
}

export const getScoreColor = (value: number) => {
	let color2 = [231, 5, 73]
	let color1 = [0, 176, 169]

	var w1 = value / 100
	var w2 = 1 - w1
	var rgb = [
		Math.round(color1[0] * w1 + color2[0] * w2),
		Math.round(color1[1] * w1 + color2[1] * w2),
		Math.round(color1[2] * w1 + color2[2] * w2),
	]
	return 'rgb(' + rgb.join(',') + ')'
}

export const getItemScenarioScore = (item: any, scenario: any) => {
	return vulnerability([item].map((s: any) => getItemAlteredByScenario(s, scenario)))
}

export const getItemScore = (item: any, evaluations: any) => {
	let indexedEvaluations = keyBy(evaluations, 'itemId') || {}

	if (indexedEvaluations[item.id]) return indexedEvaluations[item.id].value

	return 50
}

const red = [231, 5, 73]
const green = [0, 176, 169]
const orange = [39, 97, 66]

export function colorToPercent(val) {
	if (val == 'orange') return 100 / 3

	if (val == 'red') return 0

	if (val == 'green') return 100
	if (val == 'blue') return (100 * 2) / 3

	if (val === null || val === 'undefined') {
		return 50
	}

	return parseInt(val)
}

export function percentToHue(val: any) {
	if (val == 'orange') val = 100 / 3

	if (val == 'red') val = 0

	if (val == 'green') val = 100
	if (val == 'blue') val = (100 * 2) / 3

	if (val === null || val === 'undefined') {
		return [0, 100, 100]
	}

	return [
		((100 - val) * red[0] + val * green[0]) / 2,
		((100 - val) * red[1] + val * green[1]) / 2,
		((100 - val) * red[2] + val * green[2]) / 2,
	]
}

export function hue(val: any) {
	var h = percentToHue(val)

	console.log('hue rgb(' + h[0] + ', ' + h[1] + ', ' + h[2] + ')')
	return 'rgb(' + h[0] + ', ' + h[1] + ', ' + h[2] + ')'
}

export const getIndicatorOrConstraintScore = (elements: any) => {
	if (!elements || elements.length == 0) {
		return null
	}

	var total_weight = 0
	var total = 0

	for (var i = 0; i < elements.length; i++) {
		total_weight += elements[i].weight || 1

		total += colorToPercent(elements[i].trend) * (elements[i].weight || 1)
	}

	let currentPercentage = total_weight != 0 ? total / total_weight : 50

	return currentPercentage
}

export const getStakeholderScoreForScenarioDetailsColor = (element: any) => {
	let activeness = { undefined: 0, likely: 7, low: 18, medium: 33, high: 50 }[itemActiveness(element.x, element.y)]

	return element.y < 0 ? -activeness + 50 : activeness + 50
}

export const getStakeholderScoreForScenarioDetailsRanking = (element: any) => {
	let activeness = { undefined: 0, likely: 70, low: 180, medium: 330, high: 500 }[
		itemActiveness(element.x, element.y)
	]

	let weight = { low: 0, medium: 5, high: 10 }[element.impact ? element.impact : 'low']
	let trend = { green: 3, red: 0, orange: 2, neutral: 1 }[element.trend ? element.trend : 'neutral']

	let score = activeness + weight + trend
	if (element.links && element.links.length > 0) {
		let linksStrength = element.links
			? sumBy(element.links, (l) => {
					return l.strength * 0.1
			  })
			: 0
		score += Math.min(linksStrength, 10) / 100
	}

	return element.y < 0 ? -score + 500 : score + 500
}

const itemActiveness = function (x, y) {
	var d = Math.sqrt(x * x + y * y)
	//console.log('itemActiveness', x, y, d)
	if (d < radarHighToAverageBoundariesRatio * 100) return 'high'
	if (d < radarAverageToLowBoundariesRatio * 100) return 'medium'
	if (d <= 100) return 'low'
	if (d < radarLikelyToUndefinedBoundariesRatio * 100) return 'likely'
	return 'undefined'
}

const itemWeight = function (item) {
	//console.log('itemWeight', item.id, '--------------')
	var weight = 1,
		activeness = 0

	weight = { low: 0.6, medium: 2, high: 4 }[item.impact]

	activeness = { undefined: 0, likely: 0.45, low: 0.6, medium: 1.6, high: 3.5 }[itemActiveness(item.x, item.y)]

	//console.log('itemWeight', item.id, weight, item.trend)
	if (item.y > 0) weight *= { green: 1.2, red: 0.8, orange: 0.9, neutral: 1 }[item.trend ? item.trend : 'neutral']
	else weight *= { green: 0.8, red: 1.2, orange: 1.1, neutral: 1 }[item.trend ? item.trend : 'neutral']

	// Alters weight based on links
	if (item.links && item.links.length > 0) {
		let linksStrength = item.links
			? sumBy(item.links, (l) => {
					return l.strength * 0.4
			  })
			: 0
		weight += (Math.min(linksStrength, 4) / 16) * weight
	}

	//console.log('itemWeight - end ', item.id, weight)

	return item.y > 0 ? weight * activeness : -weight * activeness
}

const normalizeTotalWeight = function (total, sum_positive, sum_negative) {
	//console.log('--- normalizeTotalWeight', total, sum_positive, sum_negative);

	var max = Math.max(sum_positive, -sum_negative)
	//console.log('normalizeTotalWeight: max', max);

	var v = total

	//Math.min( total, max * 0.8 );
	//console.log('normalizeTotalWeight: Math.min( total, max * 0.8 )', v);

	//v = Math.max( v, -max * 0.8 );
	//console.log('normalizeTotalWeight: Math.max( v, -max * 0.8 )', v);

	if (max != 0) v = v / max
	//console.log('normalizeTotalWeight: v / max', v);

	v = -v * 80 + 50
	//console.log('normalizeTotalWeight: -v * 70 + 50', v);

	v = Math.min(100, v)
	v = Math.max(0, v)
	//console.log('normalizeTotalWeight: min 0 max 100', v);

	return v
}

export const vulnerability = function (items) {
	//console.log('-------- vulnerability', items);

	if (!items || items.length == 0) {
		return null
	}

	var total_weight = 0
	var sum_negative = 0
	var sum_positive = 0

	for (var i = 0; i < items.length; i++) {
		var weight = itemWeight(items[i])

		//console.log('vulnerability - weight after links', weight);

		total_weight += weight

		//console.log('vulnerability - final weight', items[i].y()>0? weight*activeness : -weight*activeness);

		if (weight >= 0) {
			sum_positive += weight
		} else {
			sum_negative += weight
		}
	}

	//console.log('vulnerability - min max', min, max);
	//var global_max = Math.max( max, -min );

	/*console.log(
		'vulnerability - normalizeTotalWeight',
		total_weight,
		sum_positive,
		sum_negative,
		normalizeTotalWeight(total_weight, sum_positive, sum_negative)
	)*/
	return normalizeTotalWeight(total_weight, sum_positive, sum_negative)
}

export const scenarioScore = (project: any, scenario: any) => {
	let indicatorsScore = vulnerabilityIndicators(
		(project?.indicators || []).map((i: any) => getItemAlteredByScenario(i, scenario))
	)
	let constraintsScore = vulnerabilityConstraints(
		(project?.constraints || []).map((c: any) => getItemAlteredByScenario(c, scenario))
	)
	let stakeholdersScore = vulnerability(
		(project?.stakefactors || []).map((s: any) => getItemAlteredByScenario(s, scenario))
	)
	if (stakeholdersScore !== null) stakeholdersScore = 100 - stakeholdersScore
	let score = (stakeholdersScore + indicatorsScore + constraintsScore) / 3

	return score
}
