export const getNextStep = (step, constraintsCount) => {
	console.log('getNextStep', step)

	if (step == 3) return null
	if (constraintsCount == 0 && step == 2) return null
	return step + 1
}

export const getInitialStep = (constraintsCount) => {
	return constraintsCount >= 10 ? 2 : 1
}
