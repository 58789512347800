import React from 'react'

import { useHistory } from 'react-router'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import gql from 'graphql-tag'

import { sortBy, findIndex, pick } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faInfoCircle } from '@fortawesome/pro-light-svg-icons'

import AddButton from 'components/action/AddButton'
import ScenarioEditionModal from 'components/Modal/ScenarioEditionModal'
import EditableListItem from 'components/List/EditableListItem'
import NavigationButton from 'components/action/NavigationButton'
import HeaderPortal from 'layout/header/HeaderPortal'
import { ConfirmModal } from 'components/Modal/ConfirmModal'

// Help
import TutorialPopover from 'components/Help/TutorialPopover'
import { getNextStep, getInitialStep } from 'help/scenarios'

import {
	useCreateScenarioMutation,
	useDeleteScenarioMutation,
	useUpdateConstraintScenariosMutation,
	useUpdateIndicatorScenariosMutation,
	useUpdateScenarioMutation,
	useUpdateStakefactorScenariosMutation,
} from '../../../graphql/generated'

import './Scenarios.sass'
import { Popover, Tabs, Tooltip } from 'antd'
import { scenarioScore } from 'utils/ranking'
import { ScenarioResult } from './ScenarioResult'
import { handleCreateScenario } from 'utils/graphql'

export const Scenarios = (props: any) => {
	const [editedItem, setEditedItem] = React.useState<any | null>(null)
	let history = useHistory()
	const params: any = useParams()
	const [createScenario] = useCreateScenarioMutation()
	const [deleteScenario] = useDeleteScenarioMutation()
	const [updateScenario] = useUpdateScenarioMutation()

	const [updateIndicatorScenarios] = useUpdateIndicatorScenariosMutation()
	const [updateConstraintScenarios] = useUpdateConstraintScenariosMutation()
	const [updateStakefactorScenarios] = useUpdateStakefactorScenariosMutation()

	const { t } = useTranslation()

	// Help
	const [visibleHelpStep, setVisibleHelpStep] = React.useState<any | null>(null)
	const closeHelp = () => setVisibleHelpStep(null)
	const initialStep = getInitialStep(props.scenarios?.length)
	const nextStep = getNextStep(visibleHelpStep, props.scenarios?.length)
	const onNext = () => setVisibleHelpStep(nextStep, props.scenarios?.length)
	const hasNext = !!nextStep

	console.log('current step', visibleHelpStep, 'next step', nextStep)

	// Create
	const handleAskToCreateScenario = async (referenceScenario: any) => {
		props.pushModal(
			<ScenarioEditionModal
				onHide={() => {
					props.popModal()
				}}
				onSave={async (values: any) =>
					handleCreateScenario(
						params.id,
						props,
						referenceScenario,
						values,
						() => {
							props.popModal()
						},
						{
							createScenario,
							updateIndicatorScenarios,
							updateConstraintScenarios,
							updateStakefactorScenarios,
						}
					)
				}
				id={false}
				values={{
					name: referenceScenario && referenceScenario.name ? referenceScenario.name + ' - Copy' : '',
					description:
						referenceScenario && referenceScenario.description ? referenceScenario.description : '',
					impacts: referenceScenario && referenceScenario.impacts ? referenceScenario.impacts : '',
				}}
			></ScenarioEditionModal>
		)
	}

	// Update
	const handleUpdateScenario = async (id: string, values: Object) => {
		let index = findIndex(props.scenarios, (s: any) => s.id === id)
		console.log('index', index)
		let newScenario = {
			...pick(props.scenarios[index], ['description', 'name', 'impacts']),
			...pick(values, ['description', 'name', 'impacts']),
		}

		console.log('handleUpdateScenario', newScenario)

		const result = await updateScenario({
			variables: {
				id: id,
				...newScenario,
			},
			update(cache, { data: { updateScenario } }) {
				cache.modify({
					id: `Scenario:${id}`,
					fields: {
						name() {
							return updateScenario.name || newScenario.name
						},
						description() {
							return updateScenario.description || newScenario.description
						},
						impacts() {
							return updateScenario.impacts || newScenario.impacts
						},
					},
				})
			},
			optimisticResponse: {
				updateScenario: {
					__typename: 'Scenario',
					id,
					...newScenario,
				},
			},
		})
	}

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.indicator.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDelete(id)}
				message={t('models.indicator.messages.delete_confirmation_explanation')}
			></ConfirmModal>
		)
	}

	// Delete
	const handleDelete = async (id: string | undefined) => {
		console.log('deleteScenario', id)

		if (id == null) {
			return
		}

		await deleteScenario({
			variables: { id: id },
			update(cache, { data: { deleteScenario } }) {
				cache.modify({
					id: `Project:${params.id}`,
					fields: {
						scenarios(existingScenariosRef, { readField }) {
							return existingScenariosRef.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteScenario: {
					__typename: 'Scenario',
					id: id,
				},
			},
		})
	}

	let scoreBaseline = scenarioScore(props, null)

	return (
		<div className="Scenarios pb-3">
			<HeaderPortal>
				{props.portalContent}{' '}
				<FontAwesomeIcon
					style={{ marginLeft: 'auto', marginRight: '0.5rem', cursor: 'pointer' }}
					icon={faInfoCircle}
					onClick={() => setVisibleHelpStep(initialStep)}
				/>
			</HeaderPortal>

			<h2>{t('pages.scenarios.title')}</h2>

			{editedItem && (
				<ScenarioEditionModal
					onHide={() => {
						setEditedItem(null)
					}}
					onDelete={() => {
						askDeleteConfirmation(editedItem.id)
						setEditedItem(null)
					}}
					onSave={(values: Object) => {
						handleUpdateScenario(editedItem.id, values)
						setEditedItem(null)
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						description: editedItem?.description || '',
						impacts: editedItem?.impacts || '',
					}}
				></ScenarioEditionModal>
			)}

			<Tabs type="card" className="m-auto w-100" style={{ maxWidth: '800px' }}>
				<Tabs.TabPane tab="List" key="1">
					<h3 className="mb-4">{t('pages.scenarios.subtitle')}</h3>

					<TutorialPopover
						onClose={closeHelp}
						placement={'top'}
						style={{ maxWidth: '400px' }}
						visible={visibleHelpStep == 2}
						body={
							'This gauge is a weighted consolidation of your scenarios assessments, to a variable extent the result of your work on stakeholders'
						}
						onNext={onNext}
						hasNext={hasNext}
					></TutorialPopover>

					<div className="mb-3 m-auto w-100" style={{ maxWidth: '800px' }}>
						<EditableListItem
							editable={false}
							key={'baseline'}
							actions={[
								<Tooltip title={t('models.scenario.actions.create_new')}>
									<FontAwesomeIcon
										icon={faClone}
										onClick={() => {
											handleAskToCreateScenario()
										}}
										style={{
											marginRight: '0.5rem',
											cursor: props.disabled ? 'not-allowed' : 'initial',
											opacity: props.disabled ? 0.5 : 1,
										}}
									/>
								</Tooltip>,
							]}
						>
							<>Baseline</>
							<Tooltip title="Scenario score">
								<div className="ml-auto">{scoreBaseline.toFixed(0)}</div>
							</Tooltip>
						</EditableListItem>

						{(props.scenarios || []).map((o: any) => {
							let score = scenarioScore(props, o)

							return (
								<EditableListItem
									key={o.id}
									onEdit={() => {
										setEditedItem(o)
									}}
									onDelete={askDeleteConfirmation}
									actions={[
										<Tooltip title={t('models.scenario.actions.clone')}>
											<FontAwesomeIcon
												icon={faClone}
												onClick={() => {
													handleAskToCreateScenario(o)
												}}
												style={{
													marginRight: '0.5rem',
													cursor: props.disabled ? 'not-allowed' : 'initial',
													opacity: props.disabled ? 0.5 : 1,
												}}
											/>
										</Tooltip>,
									]}
								>
									<>
										<div className="name">
											{o.name}
											{o.description ? <div className="description">{o.description}</div> : ''}
										</div>
										<Tooltip title="Scenario score">
											<div className="ml-auto">{score.toFixed(0)}</div>
										</Tooltip>
									</>
								</EditableListItem>
							)
						})}
					</div>

					<div className="w-100 d-flex justify-content-center align-items-center">
						<div
							className="w-100 d-flex justify-content-center align-items-center"
							style={{ width: '33%' }}
						></div>
						<div
							className="w-100 d-flex justify-content-center align-items-center"
							style={{ width: '33%' }}
						>
							{
								<AddButton
									popover={
										<TutorialPopover
											onClose={closeHelp}
											placement={'top'}
											style={{ maxWidth: '400px' }}
											visible={visibleHelpStep == 1}
											body={
												<>
													Add scenarios. <br />
													This is about measuring success. For example a goal of “being number
													1 on the market” can have several measures: Ability to reach the
													target sales in the year, ability to reach the market share in the
													year, maybe a measure of brand awareness etc. Don’t forget that one
													measure of success usually carries side-effects. For example, a
													measure of volume needs to be counter-balanced by a measure of
													quality. Beyond 10 scenarios, they may lose their significance
												</>
											}
											onNext={onNext}
											hasNext={hasNext}
										></TutorialPopover>
									}
									onClick={() => handleAskToCreateScenario()}
								></AddButton>
							}
						</div>
						<div
							className="w-100 d-flex justify-content-center align-items-center"
							style={{ width: '33%' }}
						>
							{' '}
							{props.scenarios && props.scenarios.length > 0 && (
								<TutorialPopover
									onClose={closeHelp}
									placement={'top'}
									style={{ maxWidth: '400px' }}
									visible={visibleHelpStep == 3}
									body={
										<>
											Click here once you are done with the scenarios to get into the Gerositus
											stakeholders navigator map
										</>
									}
									onNext={onNext}
									hasNext={hasNext}
								>
									<NavigationButton onClick={() => history.push('/projects/' + params?.id)}>
										Next step
									</NavigationButton>
								</TutorialPopover>
							)}
						</div>
					</div>
				</Tabs.TabPane>

				<Tabs.TabPane tab="Details" key="2">
					<ScenarioResult {...props}></ScenarioResult>
				</Tabs.TabPane>
			</Tabs>
		</div>
	)
}
