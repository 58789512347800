import React from 'react'
import ActionButton from './ActionButton'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

export const AddButton = (props: any) => {
	return (
		<ActionButton
			popover={props.popover}
			tooltip={props.tooltip}
			className={props.className}
			id={props.id}
			onClick={props.onClick}
		>
			<FontAwesomeIcon icon={faPlus} />
		</ActionButton>
	)
}

export default AddButton
