import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons'

import './Weight.sass'

function Weight(props: any) {
	return (
		<div
			className={[
				'Weight',
				props.dark === true ? 'dark' : 'light',
				props.count === 1 ? 'single' : '',
				props.disabled ? 'disabled' : '',
			].join(' ')}
			onClick={props.disabled ? () => {} : props.onClick}
			onDoubleClick={props.disabled ? () => {} : props.onClick}
		>
			<div className={props.children === null || (props.children && props.children >= 1) ? 'filled' : ''}>
				{(!props.count || props.count == 1) && <FontAwesomeIcon icon={faCheck} />}
			</div>
			{(!props.count || props.count > 1) && (
				<div className={props.children && props.children >= 2 ? 'filled' : ''}>
					{(!props.count || props.count == 1) && <FontAwesomeIcon icon={faCheck} />}
				</div>
			)}
			{(!props.count || props.count > 2) && (
				<div className={props.children && props.children >= 3 ? 'filled' : ''}>
					{(!props.count || props.count == 1) && <FontAwesomeIcon icon={faCheck} />}
				</div>
			)}
		</div>
	)
}

export default Weight
