export const getNextStep = (step, indicatorsCount) => {
	console.log('getNextStep', step)

	if (step == 3) return null
	if (indicatorsCount == 0 && step == 2) return null
	return step + 1
}

export const getInitialStep = (indicatorsCount) => {
	return indicatorsCount >= 10 ? 2 : 1
}
