import { sortBy, findIndex } from 'lodash'

import EditableListItem from 'components/List/EditableListItem'

export const LinksList = (props: any) => {
	return (
		<div className="mb-3 w-100">
			{sortBy(props.items || [], 'name').map((o: any) => (
				<EditableListItem
					deletable={true}
					editable={false}
					key={o.id + o.strength}
					onEdit={() => {
						//setEditedItem(o);
					}}
					weight={o.strength}
					onDelete={props.onDelete}
					onCycleWeight={(values: any) => {
						console.log('values', values.strength)

						let newWeight = values.strength ? values.strength + 1 : 2
						if (newWeight > 3) newWeight = 1

						console.log('newWeight', newWeight)

						if (props.onCycleStrength) props.onCycleStrength(o, newWeight)
					}}
					{...o}
				></EditableListItem>
			))}
		</div>
	)
}

export default LinksList
