import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Popover, Tag } from 'antd'
import gql from 'graphql-tag'

import { findIndex, pick, sortBy } from 'lodash'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
	useCreateIndicatorMutation,
	useUpdateIndicatorMutation,
	useUpdateIndicatorScenariosMutation,
	useDeleteIndicatorMutation,
	useCreateConstraintMutation,
	useUpdateConstraintMutation,
	useUpdateConstraintScenariosMutation,
	useDeleteConstraintMutation,
} from '../../../../graphql/generated'

import { SynthesisGraph } from './SynthesisGraph'
import MiniIndicatorVulnerabilityScale from 'pages/Project/Indicators/MiniIndicatorVulnerabilityScale'
import MiniConstraintVulnerabilityScale from 'pages/Project/Constraints/MiniConstraintVulnerabilityScale'
import { ElementPopover } from './ElementPopover'
import IndicatorEditionModal from 'components/Modal/IndicatorEditionModal'
import ConstraintEditionModal from 'components/Modal/ConstraintEditionModal'
import ConfirmModal from 'components/Modal/ConfirmModal'

import './SummaryColumn.sass'
import { getScoreColor, hue } from 'utils/ranking'
import { getItemAlteredByScenario } from 'utils/rules'

export const SummaryColumn = (props: any) => {
	const { t } = useTranslation()
	const params: any = useParams()

	const [editedItem, setEditedItem] = React.useState<any | null>(null)
	const [createIndicator] = useCreateIndicatorMutation()
	const [updateIndicator] = useUpdateIndicatorMutation()
	const [updateIndicatorScenarios] = useUpdateIndicatorScenariosMutation()
	const [deleteIndicator] = useDeleteIndicatorMutation()

	const [createConstraint] = useCreateConstraintMutation()
	const [updateConstraint] = useUpdateConstraintMutation()
	const [updateConstraintScenarios] = useUpdateConstraintScenariosMutation()
	const [deleteConstraint] = useDeleteConstraintMutation()

	const askDeleteIndicatorConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.indicator.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDeleteIndicator(id)}
				message={t('models.indicator.messages.delete_confirmation_explanation')}
			></ConfirmModal>
		)
	}

	const askDeleteConstraintConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.constraint.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDeleteConstraint(id)}
				message={t('models.indicator.messages.delete_confirmation_explanation')}
			></ConfirmModal>
		)
	}

	// Create indicator
	const handleCreateIndicator = async () => {
		console.log('props', props)

		props.pushModal(
			<IndicatorEditionModal
				onHide={() => {
					props.popModal()
				}}
				onSave={async (values: any) => {
					let variables = {
						projectId: params.id,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						description: values.description || '',
					}

					let result = await createIndicator({
						variables,
						update(cache, { data: { createIndicator } }) {
							cache.modify({
								id: `Project:${params.id}`,
								fields: {
									indicators(indicators = []) {
										const newIndicator = cache.writeFragment({
											id: 'Indicator:' + createIndicator.id,
											data: createIndicator,
											fragment: gql`
												fragment IndicatorFragment on Indicator {
													name
													weight
													projectId
													description
												}
											`,
										})

										return [...indicators, newIndicator]
									},
								},
							})
						},
						optimisticResponse: {
							createIndicator: {
								id: 'temp-id',
								...variables,
							},
						},
					})

					props.popModal()
				}}
				id={false}
				values={{
					name: '',
					weight: 1,
					description: '',
				}}
			></IndicatorEditionModal>
		)
	}

	// Delete
	const handleDeleteIndicator = async (id: string | undefined) => {
		console.log('deleteIndicator', id)

		if (id == null) {
			return
		}

		await deleteIndicator({
			variables: { id: id },
			update(cache, { data: { deleteIndicator } }) {
				cache.modify({
					id: `Project:${params.id}`,
					fields: {
						indicators(existingIndicatorsRef, { readField }) {
							return existingIndicatorsRef.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteIndicator: {
					__typename: 'Indicator',
					id: id,
				},
			},
		})
	}

	// Delete
	const handleDeleteConstraint = async (id: string | undefined) => {
		console.log('deleteConstraint', id)

		if (id == null) {
			return
		}

		await deleteConstraint({
			variables: { id: id },
			update(cache, { data: { deleteConstraint } }) {
				cache.modify({
					id: `Project:${params.id}`,
					fields: {
						constraints(existingConstraintsRef, { readField }) {
							return existingConstraintsRef.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteConstraint: {
					__typename: 'Constraint',
					id: id,
				},
			},
		})
	}

	// Create constraint
	const handleCreateConstraint = async () => {
		console.log('props', props)

		props.pushModal(
			<ConstraintEditionModal
				onHide={() => {
					props.popModal()
				}}
				onSave={async (values: any) => {
					let variables = {
						projectId: params.id,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						description: values.description || '',
					}

					let result = await createConstraint({
						variables,
						update(cache, { data: { createConstraint } }) {
							cache.modify({
								id: `Project:${params.id}`,
								fields: {
									constraints(indicators = []) {
										const newConstraint = cache.writeFragment({
											id: 'Constraint:' + createConstraint.id,
											data: createConstraint,
											fragment: gql`
												fragment ConstraintFragment on Constraint {
													name
													weight
													projectId
													description
												}
											`,
										})

										return [...indicators, newConstraint]
									},
								},
							})
						},
						optimisticResponse: {
							createConstraint: {
								id: 'temp-id',
								...variables,
							},
						},
					})

					props.popModal()
				}}
				id={false}
				values={{
					name: '',
					weight: 1,
					description: '',
				}}
			></ConstraintEditionModal>
		)
	}

	// Update
	const handleUpdateIndicator = async (id: string, values: Object) => {
		let index = findIndex(props.indicators, (s: any) => s.id === id)
		console.log('index', index)
		let newIndicator = {
			...pick(props.indicators[index], ['weight', 'description', 'name', 'trend']),
			...pick(values, ['weight', 'description', 'name', 'trend']),
		}

		console.log('handleUpdateIndicator', newIndicator)

		if (props.scenario) {
			let newScenarios = {
				...(props.indicators[index].scenarios ? JSON.parse(props.indicators[index].scenarios) : {}),
			}
			if (!newScenarios[props.scenario.id]) newScenarios[props.scenario.id] = {}
			newScenarios[props.scenario.id].trend = values.trend

			optimisticlyUpdateScenario(props.indicators[index].id, 'Indicator', newScenarios)
		} else {
			const result = await updateIndicator({
				variables: {
					id: id,
					...newIndicator,
				},
				update(cache, { data: { updateIndicator } }) {
					cache.modify({
						id: `Indicator:${id}`,
						fields: {
							name() {
								return updateIndicator.name || newIndicator.name
							},
							description() {
								return updateIndicator.description || newIndicator.description
							},
							weight() {
								return updateIndicator.weight || newIndicator.weight
							},
							trend() {
								return updateIndicator.trend || newIndicator.trend
							},
						},
					})
				},
				optimisticResponse: {
					updateIndicator: {
						__typename: 'Indicator',
						id,
						...newIndicator,
					},
				},
			})
		}
	}

	// Update
	const handleUpdateConstraint = async (id: string, values: Object) => {
		let index = findIndex(props.constraints, (s: any) => s.id === id)
		console.log('index', index)
		let newConstraint = {
			...pick(props.constraints[index], ['weight', 'description', 'name', 'trend']),
			...pick(values, ['weight', 'description', 'name', 'trend']),
		}

		console.log('handleUpdateConstraint', newConstraint)

		if (props.scenario) {
			let newScenarios = {
				...(props.constraints[index].scenarios ? JSON.parse(props.constraints[index].scenarios) : {}),
			}
			if (!newScenarios[props.scenario.id]) newScenarios[props.scenario.id] = {}
			newScenarios[props.scenario.id].trend = values.trend

			optimisticlyUpdateScenario(props.constraints[index].id, 'Constraint', newScenarios)
		} else {
			const result = await updateConstraint({
				variables: {
					id: id,
					...newConstraint,
				},
				update(cache, { data: { updateConstraint } }) {
					cache.modify({
						id: `Constraint:${id}`,
						fields: {
							name() {
								return updateConstraint.name || newConstraint.name
							},
							description() {
								return updateConstraint.description || newConstraint.description
							},
							weight() {
								return updateConstraint.weight || newConstraint.weight
							},
							trend() {
								return updateConstraint.trend || newConstraint.trend
							},
						},
					})
				},
				optimisticResponse: {
					updateConstraint: {
						__typename: 'Constraint',
						id,
						...newConstraint,
					},
				},
			})
		}
	}

	const optimisticlyUpdateScenario = (id, type, newScenarios) => {
		let method = {
			//Stakefactor: updateStakefactorScenarios,
			Indicator: updateIndicatorScenarios,
			Constraint: updateConstraintScenarios,
		}

		method[type]({
			variables: { id: id, scenarios: JSON.stringify(newScenarios) },
			update(cache) {
				cache.modify({
					id: `${type}:${id}`,
					fields: {
						scenarios() {
							return JSON.stringify(newScenarios)
						},
					},
				})
			},

			optimisticResponse: {
				[`update${type}Scenarios`]: {
					__typename: type,
					id,
					scenarios: JSON.stringify(newScenarios),
				},
			},
		})
	}

	return (
		<aside id="SummaryColumn" className={['noselection ', props.expanded ? 'expanded' : ''].join(' ')}>
			<SynthesisGraph
				indicators={props.indicators}
				constraints={props.constraints}
				stakefactors={props.stakefactors}
				scenario={props.scenario}
				scenarios={props.scenarios}
			></SynthesisGraph>
			<div className="content">
				<div className="text-left mt-4 mb-2 d-flex flex-column align-items-left">
					<span className="text-nowrap mb-1" style={{ marginRight: '1rem' }}>
						{t('pages.indicators.title')}
					</span>

					<MiniIndicatorVulnerabilityScale
						scenario={props.scenario}
						scenarios={props.scenarios}
						indicators={(props.indicators || []).map((i) => getItemAlteredByScenario(i, props.scenario))}
					></MiniIndicatorVulnerabilityScale>
				</div>
				<div className="text-left">
					{props.indicators && props.indicators.length ? (
						sortBy(props.indicators || [], 'name').map((i: any, index: number) => {
							let alteredItem = getItemAlteredByScenario(i, props.scenario)

							return (
								<Popover
									key={index}
									trigger={'click'}
									placement="topLeft"
									title={
										<div>
											{i.name}{' '}
											<FontAwesomeIcon
												style={{ marginLeft: '0.5rem', fontSize: '1.2rem', cursor: 'pointer' }}
												icon={faEdit}
												onClick={() => setEditedItem(i)}
											/>
										</div>
									}
									content={
										<ElementPopover
											{...alteredItem}
											originalElement={i}
											scenario={props.scenario}
											scenarios={props.scenarios}
											onUpdate={handleUpdateIndicator}
										></ElementPopover>
									}
								>
									<Tag className={['tag ImpactedIndicator unselected'].join(' ')}>
										<div
											className={'Trend v' + alteredItem.trend}
											style={
												alteredItem.trend == '50'
													? {}
													: { background: getScoreColor(alteredItem.trend) }
											}
										></div>
										{i.name}
									</Tag>
								</Popover>
							)
						})
					) : (
						<div className="text-center" style={{ color: 'rgba(46, 160, 223, 0.3)' }}>
							{t('models.indicator.no_x')}
						</div>
					)}
					{!props.scenario && (
						<Tag onClick={handleCreateIndicator} className={'tag ImpactedIndicator unselected'}>
							+
						</Tag>
					)}
				</div>

				<div className="text-left mt-4 mb-2 d-flex flex-column align-items-left">
					<span className="text-nowrap mb-1" style={{ marginRight: '1rem' }}>
						{t('pages.constraints.title')}
					</span>

					<MiniConstraintVulnerabilityScale
						scenario={props.scenario}
						scenarios={props.scenarios}
						constraints={(props.constraints || []).map((c) => getItemAlteredByScenario(c, props.scenario))}
					></MiniConstraintVulnerabilityScale>
				</div>
				<div className="text-left">
					{props.constraints && props.constraints.length ? (
						sortBy(props.constraints || [], 'name').map((i: any, index: number) => {
							let alteredItem = getItemAlteredByScenario(i, props.scenario)

							return (
								<Popover
									key={index}
									trigger={'click'}
									title={
										<div>
											{i.name}{' '}
											<FontAwesomeIcon
												style={{ marginLeft: '0.5rem', fontSize: '1.2rem', cursor: 'pointer' }}
												icon={faEdit}
												onClick={() => setEditedItem(i)}
											/>
										</div>
									}
									content={
										<ElementPopover
											{...alteredItem}
											originalElement={i}
											onUpdate={handleUpdateConstraint}
											scenario={props.scenario}
											scenarios={props.scenarios}
										></ElementPopover>
									}
								>
									<Tag className={['tag ImpactedConstraint unselected'].join(' ')}>
										<div
											className={'Trend v' + alteredItem.trend}
											style={
												alteredItem.trend == '50'
													? {}
													: { background: getScoreColor(alteredItem.trend) }
											}
										></div>
										{i.name}
									</Tag>
								</Popover>
							)
						})
					) : (
						<div className="text-center" style={{ color: 'rgba(46, 160, 223, 0.3)' }}>
							{t('models.constraint.no_x')}
						</div>
					)}

					{!props.scenario && (
						<Tag
							onClick={handleCreateConstraint}
							className={'tag ImpactedIndicator unselected'}
							style={{ margin: 'auto' }}
						>
							+
						</Tag>
					)}
				</div>
			</div>

			{editedItem && editedItem.__typename == 'Indicator' && (
				<IndicatorEditionModal
					onHide={() => {
						setEditedItem(null)
					}}
					onDelete={() => {
						askDeleteIndicatorConfirmation(editedItem.id)
						setEditedItem(null)
					}}
					onSave={(values: Object) => {
						handleUpdateIndicator(editedItem.id, values)
						setEditedItem(null)
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						weight: editedItem?.weight || 1,
						description: editedItem?.description || '',
					}}
				></IndicatorEditionModal>
			)}

			{editedItem && editedItem.__typename == 'Constraint' && (
				<ConstraintEditionModal
					onHide={() => {
						setEditedItem(null)
					}}
					onDelete={() => {
						askDeleteConstraintConfirmation(editedItem.id)
						setEditedItem(null)
					}}
					onSave={(values: Object) => {
						handleUpdateConstraint(editedItem.id, values)
						setEditedItem(null)
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						weight: editedItem?.weight || 1,
						description: editedItem?.description || '',
					}}
				></ConstraintEditionModal>
			)}
		</aside>
	)
}
