// @ts-nocheck
import React from 'react'

import { Slider as SliderAntd, Tooltip } from 'antd'
import './MiniSlider.sass'

export const MiniSlider = (props: any) => {
	const [score, setScore] = React.useState<any | null>(0)

	return (
		<div
			className={props.className + ' MiniSliderWrapper ' + (props.readOnly ? 'readonly' : '')}
			onClick={props.onClick}
			style={props.style}
		>
			{props.additionalMarkers &&
				props.additionalMarkers.map((m, index) => {
					return (
						<Tooltip title={m.name}>
							<div className="marker" style={{ left: m.value + '%' }}></div>
						</Tooltip>
					)
				})}

			{props.readOnly ? (
				<SliderAntd
					min={0}
					disabled={props.readOnly ? props.readOnly : false}
					step={10}
					max={100}
					onAfterChange={(value) => {
						if (props.onChange) props.onChange(value)
					}}
					defaultValue={props.value || 0}
					value={props.readOnly ? props.value : undefined}
					tooltipVisible={false}
				/>
			) : (
				<SliderAntd
					min={0}
					step={10}
					max={100}
					onAfterChange={(value) => {
						if (props.onChange) props.onChange(value)
					}}
					defaultValue={props.value || 0}
					tooltipVisible={false}
				/>
			)}
		</div>
	)
}

// <animated.div style={{ transform: `translate3d(${x}px,-5px,0)` }}>	</animated.div>
