export default {
	title: 'Radar',
	context_best: 'Favorable',
	context_worse: 'Hostile',
	tabs: {
		general: {
			title: 'General',
		},
		links: {
			title: 'Links',
		},
		comments: {
			title: 'History',
		},
		filters: {
			title: 'View filters',
		},
		tags: {
			title: 'Tags',
			assigned: 'Assigned tags',
			unassigned: 'Unassigned tags',
		},
	},
	all_tags: 'Tags filter',
	links: 'Display all links',
	no_tag: 'No tag',

	all_icons: 'Icons filter',
	no_icon: 'No icon',

	all_indicators: 'Fundamentals filter',
	all_constraints: 'Constraints filter',
}
