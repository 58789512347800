export default function (options) {
	this.originalSize = 560
	this.boundariesRatio = (225 * 2) / this.originalSize

	this.normalizerRatio = (size) => {
		//console.log('normalizerRatio', size, (1 / size) * 200);

		return ((1 / size) * 200) / this.boundariesRatio
	}

	this.item_scale = function () {
		if (this.items.length < 5) {
			return 1
		}
		return Math.max(1 - 0.04 * (this.items.length - 5), 0.75)
	}

	this.itemWidth = (data, mode) => {
		return {
			low: !mode ? 30 : 25,
			medium: !mode ? 50 : 35,
			high: !mode ? 60 : 45,
		}[data.importance()]
	}

	this.item_style = function (data, mode) {
		var coords = this.denormalizeCoordinates(data._x, data._y)
		var width = this.item_width(data, mode)

		return {
			transform: 'translate3d(' + coords[0] + 'px, ' + coords[1] + 'px, 0px) scale(' + this.item_scale() + ')',
			width: width + 'px',
			height: width + 'px',
			'margin-left': -width / 2 + 'px',
			'margin-top': -width / 2 + 'px',
		}
	}

	this.item_classes = function (data, selection) {
		var classes = {}

		classes['neutral-trend'] = data.trend == 'neutral'
		classes['undefined-trend'] = data.trend == 'undefined'
		classes['good-trend'] = data.trend == 'positive'
		classes['bad-trend'] = data.trend == 'negative'
		classes['objective'] = data.item_type == 'objective'
		classes['risk'] = data.item_type == 'risk'
		classes['stakeholder'] = data.item_type == 'stakeholder'
		classes['notvisible'] = !data.visible
		classes['selected'] = selection && selection.id === data.id

		console.log('item_classes', classes)

		return classes
	}

	// Returns CSS style of an history line on the radar
	this.history_line_style = function (start, end, e) {
		var p1 = this.denormalizeCoordinates(start.x(), start.y())
		var p2 = this.denormalizeCoordinates(end.x(), end.y())

		var length = Math.sqrt((p1[0] - p2[0]) * (p1[0] - p2[0]) + (p1[1] - p2[1]) * (p1[1] - p2[1]))
		var angle = (Math.atan2(p2[1] - p1[1], p2[0] - p1[0]) * 180) / Math.PI

		return {
			background: 'linear-gradient( to left, orange 4px, transparent 5px)',
			'background-size': '8px',
			width: length + 'px',
			height: '2px',
			position: 'absolute',
			'transform-origin': '0% 0%',
			transform: 'rotate(' + angle + 'deg)',
			left: p1[0] - 1 + 'px',
			top: p1[1] - 1 + 'px',
		}
	}

	// Translates normalized coordinates (-100->+100) to coordinates in pixels that works in current view
	this.denormalizeCoordinates = (width, height, offsetX, offsetY, x, y) => {
		//console.log('denormalizeCoordinates', x, y, 'ratio', this.normalizerRatio(size), 'size', size);

		return [
			x / this.normalizerRatio(width) + width / 2 + offsetX,
			-y / this.normalizerRatio(height) + height / 2 + offsetY,
		]
	}

	this.denormalizeCoordinatesInner = (width, height, x, y) => {
		//console.log('denormalizeCoordinates', x, y, 'ratio', this.normalizerRatio(size), 'size', size);

		return [x / this.normalizerRatio(width) + width / 2, -y / this.normalizerRatio(height) + height / 2]
	}

	// Translates denormalized coordinates to coordinates (-100->+100)
	this.normalizeCoordinates = function (width, height, offsetX, offsetY, x, y) {
		//console.log('x', x, '=>', x - this.offsetX);

		//console.log('y', y, '=>', y - this.offsetY);

		x = x - offsetX
		y = y - offsetY

		/*console.log('normalizeCoordinates', [
			(x - width / 2) * this.normalizerRatio(width),
			-(y - height / 2) * this.normalizerRatio(height),
		]);*/

		//console.log('transform_coordinates', x, y);
		return [(x - width / 2) * this.normalizerRatio(width), -(y - height / 2) * this.normalizerRatio(height)]
	}

	/******************************
	/* Drag & Drop
	******************************/
	this.is_dragging = false
	this.drag_element = null

	this.drag_start_handler = function (e) {
		//
		//app.is_context_menu_visible(false);
		/*
		if ($(e.target).closest('.item').length == 0) {
			this.drag_element = false;
			return false;
		} else {
			this.is_dragging(true);
			this.drag_element = e.target;
		}*/
	}

	this.drag_handler = function (e) {
		if (!this.drag_element) {
			return false
		}

		var coords = this.normalizeCoordinates(e.center.x, e.center.y)
		this.drag_element._x(coords[0])
		this.drag_element._y(coords[1])
	}

	this.drag_end_handler = function (e) {
		if (!this.drag_element) {
			return false
		}
		this.drag_element = false
		this.is_dragging(false)
	}
}

export const originalSize = 560
export const boundariesRatio = (225 * 2) / originalSize

export const highToAverageBoundariesRatio = (100 * 2) / originalSize / boundariesRatio
export const averageToLowBoundariesRatio = (165 * 2) / originalSize / boundariesRatio
export const likelyToUndefinedBoundariesRatio = (275 * 2) / originalSize / boundariesRatio

export const itemActiveness = (x, y) => {
	var d = Math.sqrt(x * x + y * y)

	if (d < highToAverageBoundariesRatio * 100) return 'high'
	if (d < averageToLowBoundariesRatio * 100) return 'medium'
	if (d <= 100) return 'low'
	if (d < likelyToUndefinedBoundariesRatio * 100) return 'likely'
	return 'undefined'
}
