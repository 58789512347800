export const getNextStep = (step, scenariosCount) => {
	console.log('getNextStep', step)

	if (step == 3) return null
	if (scenariosCount == 0 && step == 2) return null
	return step + 1
}

export const getInitialStep = (scenariosCount) => {
	return scenariosCount >= 10 ? 2 : 1
}
