import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { history } from './utils/history'
import { Auth0Provider } from './react-auth0-spa'
import authConfig from './auth-config.json'
import { AuthorizedApolloProvider } from './authorized-apollo-client'
import { AuthorizedApolloProvider as AuthorizedApolloProviderWithoutAuth0 } from './authorized-apollo-client-without-auth0'
import './i18n'

// @ts-ignore
let subdomain: string = document.location.hostname.replace(/\.gerositus.*/, '')
const config =
	process.env.REACT_APP_MODE === 'corporate'
		? authConfig[process.env.REACT_APP_MODE][subdomain][process.env.REACT_APP_ENV]
		: authConfig[process.env.REACT_APP_MODE][process.env.REACT_APP_ENV]

const onRedirectCallback = async (url?: string) => {
	history.push(url ?? window.location.pathname)
}

if (document.location.host != 'localhost:3000')
	Sentry.init({
		dsn: 'https://33d74f02ec6b4a5dbe4c7317eaede7dc@o23207.ingest.sentry.io/6492582',
		integrations: [new BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.1,
	})

ReactDOM.render(
	<React.StrictMode>
		{process.env.REACT_APP_FEATURE_TOKEN_LOGIN === 'enabled' ? (
			<AuthorizedApolloProviderWithoutAuth0>
				<App returnTo={config.returnTo} paymentPublicKey={config.paymentPublicKey} />
			</AuthorizedApolloProviderWithoutAuth0>
		) : (
			<Auth0Provider
				domain={config.domain}
				client_id={config.clientID}
				redirect_uri={config.redirectUri}
				audience={config.audience}
				onRedirectCallback={onRedirectCallback}
			>
				<AuthorizedApolloProvider>
					<App returnTo={config.returnTo} paymentPublicKey={config.paymentPublicKey} />
				</AuthorizedApolloProvider>
			</Auth0Provider>
		)}
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
