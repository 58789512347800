import React, { Component } from 'react'

import { findIndex, keyBy, pick, flatten, uniq, sortBy, debounce, omit, compact } from 'lodash'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import gql from 'graphql-tag'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, Tag, Tooltip } from 'antd'
import prompt from 'antd-prompt'
import { faTriangle } from '@fortawesome/pro-solid-svg-icons'
import { faInfoCircle, fal } from '@fortawesome/pro-light-svg-icons'

import { IconPicker } from 'components/IconPicker'
import MainRadar from 'components/Radar/MainRadar'
import VulnerabilityScale from 'components/Radar/VulnerabilityScale'
import AddButton from 'components/action/AddButton'
import StakefactorModal from 'components/Modal/StakefactorModal'
import ResponsiveWrapper from 'components/Utils/ResponsiveWrapper'
import { SummaryColumn } from './SummaryColumn/SummaryColumn'

import Weight from 'components/controls/Weight'
import LinksTab from './Tabs/LinksTab'
import TagsTab from './Tabs/TagsTab'
import CommentsTab from './Tabs/CommentsTab'
import GeneralTab from './Tabs/GeneralTab'
import { ConfirmModal } from 'components/Modal/ConfirmModal'

// Help
import TutorialPopover from 'components/Help/TutorialPopover'
import { getNextStep, getInitialStep } from 'help/navigator'

import {
	useCreateStakefactorMutation,
	useUpdateStakefactorPositionMutation,
	useUpdateStakefactorIconMutation,
	useUpdateStakefactorImpactMutation,
	useUpdateStakefactorTagsMutation,
	useUpdateStakefactorTrendMutation,
	useDeleteStakefactorMutation,
	useUpdateStakefactorMutation,
	useUpdateStakefactorScenariosMutation,
	useCreateStakefactorLinkMutation,
	useDeleteStakefactorLinkMutation,
	useUpdateStakefactorLinkStrengthMutation,
} from '../../../graphql/generated'

import './Navigator.sass'
import HeaderPortal from 'layout/header/HeaderPortal'
import { getItemAlteredByScenario } from 'utils/rules'
import RadialVulnerabilityGauge from 'components/charts/RadialVulnerabilityGauge'
import useScreenSize from 'hooks/useScreenSize'
import { remainingSpace } from 'utils/layoutUtils'

const filterStakefactors = (stakefactors, filters, scenario) => {
	if (!stakefactors) return true

	// Get tags in search
	//let tags = (filters.name.toLowerCase().match(/tag:[^, ]+/g) || []).map((t) => t.replace('tag:', ''))
	let search = filters.name.replace(/tag:[^, ]+/g, '').trim()

	let filteredStakefactors = stakefactors.filter((s: any) => {
		let matching = true
		if (search && s.name) {
			matching = s.name.toLowerCase().trim().match(search.toLowerCase())
		}

		if (filters.tags) {
			for (let i = 0; i < filters.tags.length; i++) {
				matching = matching && s.tags && s.tags.includes(filters.tags[i])
			}
		} else {
			matching = matching && s.tags.length == 0
		}

		return matching
	})

	if (filters.icon) {
		filteredStakefactors = filteredStakefactors.filter((s: any) => {
			if (!s.icon || s.icon == 'faUser') return filters.icon === 'faUser'
			return filters.icon === s.icon
		})
	}

	if (filters.indicator) {
		filteredStakefactors = filteredStakefactors.filter((s: any) => {
			return s.impactedIndicators?.map((ii: any) => ii.indicatorId).includes(filters.indicator)
		})
	}

	if (filters.constraint) {
		filteredStakefactors = filteredStakefactors.filter((s: any) => {
			return s.impactedConstraints?.map((ii: any) => ii.constraintId).includes(filters.constraint)
		})
	}

	if (scenario?.id) {
		filteredStakefactors = filteredStakefactors.map((s) => getItemAlteredByScenario(s, scenario))
	}

	return filteredStakefactors
}

// Props
interface NavigatorProps {
	stakefactors: Array<any>
	scenario: any
	scenarios: Array<any>
	selection: any
	searchTerm: String
	setSearchTerm: Function
	select: any
	deselect: any
	pushModal: any
	refetch: any
	getProject: any
	portalContent: any
	expanded: any

	displayConstraints: boolean
	displayIndicators: boolean
}

export const Navigator = (props: NavigatorProps) => {
	const params: any = useParams()
	const { t } = useTranslation()

	// Stakefactor
	const [createStakefactor] = useCreateStakefactorMutation()
	const [updateStakefactor] = useUpdateStakefactorMutation()
	const [updateStakefactorScenarios] = useUpdateStakefactorScenariosMutation()
	const [updateStakefactorPosition] = useUpdateStakefactorPositionMutation()
	const [updateStakefactorIcon] = useUpdateStakefactorIconMutation()
	const [updateStakefactorImpact] = useUpdateStakefactorImpactMutation()
	const [updateStakefactorTags] = useUpdateStakefactorTagsMutation()
	const [updateStakefactorTrend] = useUpdateStakefactorTrendMutation()
	const [deleteStakefactor] = useDeleteStakefactorMutation()

	// Stakefactor links
	const [createStakefactorLink] = useCreateStakefactorLinkMutation()
	const [deleteStakefactorLink] = useDeleteStakefactorLinkMutation()
	const [updateStakefactorLinkStrength] = useUpdateStakefactorLinkStrengthMutation()

	const [editedStakefactor, setEditedStakefactor] = React.useState<any | null>(null)
	const [mode, setMode] = React.useState<any | null>({ name: 'initial' })
	const [isTransitioning, setIsTransitioning] = React.useState<any | null>(false)
	const [areAllLinksVisible, setAreAllLinksVisible] = React.useState<any | null>(0)
	const [tagFilter, setTagFilter] = React.useState<any | null>([])
	const [iconFilter, setIconFilter] = React.useState<any | null>(null)
	const [indicatorFilter, setIndicatorFilter] = React.useState<any | null>(null)
	const [constraintFilter, setConstraintFilter] = React.useState<any | null>(null)

	const [opened, setOpened] = React.useState<true | false>(true)
	const [selectedTab, setSelectedTab] = React.useState<string>('general')
	const [selectedGlobalTab, setSelectedGlobalTab] = React.useState<string>('filters')

	const [hasRadarLegend, setHasRadarLegend] = React.useState<true | false>(true)

	const filteredStakefactors = filterStakefactors(
		props.stakefactors || [],
		{
			name: props.searchTerm,
			tags: tagFilter,
			icon: iconFilter,
			indicator: indicatorFilter,
			constraint: constraintFilter,
		},
		props.scenario
	)

	const updateTimer = React.useRef(null)
	React.useEffect(() => {
		if (!updateTimer.current) {
			setIsTransitioning(true)
		}
	}, [props.scenario])

	const screenSize = useScreenSize()
	let blocks = compact([
		{ id: 'header', verticalAlign: 'top', height: 70 },
		props.displayConstraints && props.displayIndicators
			? { id: 'summary', horizontalAlign: 'left', width: 310 }
			: { id: 'summary', horizontalAlign: 'left', width: 10 },
		{ id: 'right', horizontalAlign: 'right', width: 310 },
		{ id: 'bottom', verticalAlign: 'bottom', height: 10 },
	])

	let remainingSpaceForCenter = remainingSpace({
		...screenSize,
		blocks,
	})
	//console.log('remainingSpaceForCenter', remainingSpaceForCenter)

	// Enable / Disable left text close to radar
	let maxWidth = Math.min(remainingSpaceForCenter.width, remainingSpaceForCenter.height)
	let offsetX = (remainingSpaceForCenter.width - maxWidth) / 2 + remainingSpaceForCenter.x1 - 89
	let offsetY = (remainingSpaceForCenter.height - maxWidth) / 2 + 70

	//console.log('offsetX', offsetX)
	//console.log('offsetY', offsetY)

	if (remainingSpaceForCenter.width < remainingSpaceForCenter.height) {
		if (hasRadarLegend) {
			setHasRadarLegend(false)
		}
	} else {
		if (!hasRadarLegend) {
			setHasRadarLegend(true)
		}
	}

	// Help
	const [visibleHelpStep, setVisibleHelpStep] = React.useState<any | null>(null)
	const closeHelp = () => setVisibleHelpStep(null)
	const initialStep = getInitialStep()
	const nextStep = getNextStep(visibleHelpStep)
	const onNext = () => setVisibleHelpStep(nextStep)
	const hasNext = !!nextStep

	const allTags: any = uniq(
		flatten(
			(props.stakefactors || []).map((i: any) => {
				return i.tags || []
			})
		).map((i: string) => i.trim())
	).sort()

	const allIcons: any = uniq((props.stakefactors || []).map((i: any) => (i.icon ? i.icon : 'faUser'))).sort()

	const indexedStakefactors = keyBy(props.stakefactors || [], 'id')

	//console.log('props.stakefactors', props.stakefactors)

	// Create
	const handleCreateStakefactor = async (values, defaults) => {
		console.log('defaults', defaults)

		let variables = {
			projectId: params.id,
			...(defaults ? omit(defaults, ['id']) : {}),
			name: (defaults && defaults?.name) || values?.name,
			description: (defaults && defaults?.description) || values?.description || '',
			tags: (defaults && defaults?.tags) || values?.tags || [],
			trend: (defaults && defaults?.trend) || values?.trend || null,
			impact: (defaults && defaults?.impact) || values?.impact || null,
			icon: (defaults && defaults?.icon) || values?.icon || null,
			x: (defaults && defaults?.x) + 5 || values?.x || 0,
			y: (defaults && defaults?.y) + 5 || values?.y || 0,
		}

		await createStakefactor({
			variables,
			update(cache, { data: { createStakefactor } }) {
				cache.modify({
					id: `Project:${params.id}`,
					fields: {
						stakefactors(stakefactors = []) {
							const newStakefactor = cache.writeFragment({
								id: 'Stakefactor:' + createStakefactor.id,
								data: createStakefactor,
								fragment: gql`
									fragment StakefactorFragment on Stakefactor {
										name
										description
										impact
										trend
										links
										tags
										icon
										x
										y
										impactedIndicators
									}
								`,
							})

							// Reselect after clone
							if (defaults?.id) {
								if (props.selection) props.select(createStakefactor)
							}

							return [...stakefactors, newStakefactor]
						},
					},
				})
			},
			optimisticResponse: {
				createStakefactor: {
					id: 'temp-id',
					...variables,
					__typename: 'Stakefactor',
					links: [],
				},
			},
		})
	}

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.item.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDeleteStakefactor(id)}
				message={t('models.item.messages.delete_confirmation_explanation')}
			></ConfirmModal>
		)
	}

	// Delete
	const handleDeleteStakefactor = async (id) => {
		await deleteStakefactor({
			variables: {
				id,
			},

			update(cache, { data: { deleteStakefactor } }) {
				cache.modify({
					id: `Project:${deleteStakefactor.id}`,
					fields: {
						stakefactors(existingRefs, { readField }) {
							return existingRefs.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteStakefactor: {
					__typename: 'Stakefactor',
					id: id,
				},
			},
		})

		props.deselect()
		props.refetch()
	}

	// Create
	const handleCreateStakefactorLink = async (origin, target) => {
		let result = await createStakefactorLink({
			variables: {
				projectId: params.id,
				originId: origin.id,
				targetId: target.id,
			},
			update(cache, { data: { createStakefactorLink } }) {
				cache.modify({
					id: `Stakefactor:${origin.id}`,
					fields: {
						links(existingStakefactorsLinksRef, { readField }) {
							const newStakefactorLink = cache.writeFragment({
								id: 'StakefactorLink:' + createStakefactorLink.id,
								data: createStakefactorLink,
								fragment: gql`
									fragment StakefactorFragment on Stakefactor {
										originId
										targetId
									}
								`,
							})
							return [...existingStakefactorsLinksRef, newStakefactorLink]
						},
					},
				})
			},

			optimisticResponse: {
				createStakefactorLink: {
					__typename: 'StakefactorLink',
					id: origin.id + '-' + target.id,
					originId: origin.id,
					targetId: target.id,
				},
			},
		})
	}

	// Delete
	const handleDeleteStakefactorLink = async (stakefactorId: string, id: string) => {
		console.log('handleDeleteStakefactorLink', id)

		await deleteStakefactorLink({
			variables: {
				projectId: params.id,
				id,
			},

			update(cache, { data: { deleteStakefactorLink } }) {
				cache.modify({
					id: `Stakefactor:${stakefactorId}`,
					fields: {
						links(existingLinksRef, { readField }) {
							return existingLinksRef.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteStakefactorLink: {
					__typename: 'StakefactorLink',
					id: id,
				},
			},
		})
	}

	const handleChangeStakefactorLinkStrength = (link: any, strength: number) => {
		console.log('handleChangeStakefactorLinkStrength', strength)

		updateStakefactorLinkStrength({
			variables: { id: link.id, strength },
			update(cache, { data: { updateStakefactorLinkStrength } }) {
				cache.modify({
					id: `StakefactorLink:${updateStakefactorLinkStrength.id}`,
					fields: {
						strength() {
							return strength
						},
					},
				})
			},

			optimisticResponse: {
				updateStakefactorLinkStrength: {
					__typename: 'StakefactorLink',
					id: link.id,
					strength: strength,
					originId: link.originId,
					targetId: link.targetId,
				},
			},
		})
	}

	// Edit
	const handleEditStakefactor = async (id) => {
		let index = findIndex(props.stakefactors, (s: any) => s.id === id)

		setEditedStakefactor(props.stakefactors[index])
	}

	const handleChangeItemPosition = (index: number, x: any, y: any) => {
		console.log('handleChangeItemPosition', x, y)

		if (props.scenario) {
			let newScenarios = {
				...(props.stakefactors[index].scenarios ? JSON.parse(props.stakefactors[index].scenarios) : {}),
			}
			if (!newScenarios[props.scenario.id]) newScenarios[props.scenario.id] = {}
			newScenarios[props.scenario.id].x = x
			newScenarios[props.scenario.id].y = y

			optimisticlyUpdateScenario(props.stakefactors[index].id, 'Stakefactor', newScenarios)

			if (props.selection) props.select({ ...props.selection, x, y })
		} else {
			updateStakefactorPosition({
				variables: { id: props.stakefactors[index].id, x, y },
				update(cache) {
					cache.modify({
						id: `Stakefactor:${props.stakefactors[index].id}`,
						fields: {
							x() {
								return x
							},
							y() {
								return y
							},
						},
					})
				},

				optimisticResponse: {
					updateStakefactorPosition: {
						__typename: 'Stakefactor',
						id: props.stakefactors[index].id,
						x,
						y,
					},
				},
			})

			if (props.selection) props.select({ ...props.selection, x, y })
		}
	}

	const handleChangeItemIcon = (id: string, icon: any) => {
		console.log('handleChangeItemIcon', icon)

		updateStakefactorIcon({
			variables: { id: id, icon },
			update(cache) {
				cache.modify({
					id: `Stakefactor:${id}`,
					fields: {
						icon() {
							return icon
						},
					},
				})
			},

			optimisticResponse: {
				updateStakefactorIcon: {
					__typename: 'Stakefactor',
					id,
					icon: icon,
				},
			},
		})

		if (props.selection) props.select({ ...props.selection, icon })
	}

	const handleChangeItemTags = (id: string, tags: string[]) => {
		console.log('handleChangeItemTags', tags)

		tags = uniq(tags.filter((t) => t && t.trim() != ''))

		updateStakefactorTags({
			variables: { id: id, tags },
			update(cache, { data: { updateStakefactorTags } }) {
				cache.modify({
					id: `Stakefactor:${id}`,
					fields: {
						tags() {
							return updateStakefactorTags.tags
						},
					},
				})
			},

			optimisticResponse: {
				updateStakefactorTags: {
					__typename: 'Stakefactor',
					tags,
				},
			},
		})

		if (props.selection) props.select({ ...props.selection, tags })
	}

	const handleChangeItemImpact = (id: string, impact: any) => {
		console.log('handleChangeItemImpact', impact)

		if (props.scenario) {
			let index = findIndex(props.stakefactors, (s: any) => s.id === id)
			let newScenarios = {
				...(props.stakefactors[index].scenarios ? JSON.parse(props.stakefactors[index].scenarios) : {}),
			}
			if (!newScenarios[props.scenario.id]) newScenarios[props.scenario.id] = {}
			newScenarios[props.scenario.id].impact = impact

			optimisticlyUpdateScenario(props.stakefactors[index].id, 'Stakefactor', newScenarios)

			if (props.selection) props.select({ ...props.selection, impact })
		} else {
			updateStakefactorImpact({
				variables: { id: id, impact },
				update(cache) {
					cache.modify({
						id: `Stakefactor:${id}`,
						fields: {
							impact() {
								return impact
							},
						},
					})
				},

				optimisticResponse: {
					updateStakefactorImpact: {
						__typename: 'Stakefactor',
						impact,
					},
				},
			})
		}

		if (props.selection) props.select({ ...props.selection, impact })
	}

	const optimisticlyUpdateScenario = (id, type, newScenarios) => {
		let method = {
			Stakefactor: updateStakefactorScenarios,
		}

		method[type]({
			variables: { id: id, scenarios: JSON.stringify(newScenarios) },
			update(cache) {
				cache.modify({
					id: `${type}:${id}`,
					fields: {
						scenarios() {
							return JSON.stringify(newScenarios)
						},
					},
				})
			},

			optimisticResponse: {
				[`update${type}Scenarios`]: {
					__typename: type,
					id,
					scenarios: JSON.stringify(newScenarios),
				},
			},
		})
	}

	const handleChangeItemTrend = (id: string, trend: any) => {
		console.log('handleChangeItemTrend', trend)

		if (props.scenario) {
			let index = findIndex(props.stakefactors, (s: any) => s.id === id)

			let newScenarios = {
				...(props.stakefactors[index].scenarios ? JSON.parse(props.stakefactors[index].scenarios) : {}),
			}
			if (!newScenarios[props.scenario.id]) newScenarios[props.scenario.id] = {}
			newScenarios[props.scenario.id].trend = trend

			optimisticlyUpdateScenario(id, 'Stakefactor', newScenarios)
		} else {
			updateStakefactorTrend({
				variables: { id: id, trend },
				update(cache) {
					cache.modify({
						id: `Stakefactor:${id}`,
						fields: {
							trend() {
								return trend
							},
						},
					})
				},

				optimisticResponse: {
					updateStakefactorTrend: {
						__typename: 'Stakefactor',
						id,
						trend,
					},
				},
			})
		}

		if (props.selection) props.select({ ...props.selection, trend })
	}

	const handleChangeItem = (id: string, values: any) => {
		let index = findIndex(props.stakefactors, (s: any) => s.id === id)

		updateStakefactor({
			variables: { id: props.stakefactors[index].id, ...values },
			update(cache, { data: { updateStakefactor } }) {
				cache.modify({
					id: `Stakefactor:${id}`,
					fields: {
						name() {
							return updateStakefactor.name
						},
						description() {
							return updateStakefactor.description
						},
					},
				})
			},

			optimisticResponse: {
				updateStakefactor: {
					__typename: 'Stakefactor',
					id,
					...props.stakefactors[index],
					...values,
				},
			},
		})

		if (props.selection) props.select({ ...props.selection, ...values })
	}

	const handleToggleTag = (t) => {
		if (props.searchTerm.match('tag:' + t)) {
			let newSearchTerm = props.searchTerm.replace('tag:' + t, '').trim()
			props.setSearchTerm(newSearchTerm)
		} else {
			props.setSearchTerm(props.searchTerm + ' tag:' + t)
		}
	}

	const handleChange = (action: string, value: string) => {
		if (!props.selection) return

		if (action == 'trend') {
			handleChangeItemTrend(props.selection?.id, value)
		} else if (action == 'impact') {
			handleChangeItemImpact(props.selection?.id, value)
		} else if (action == 'edit') {
			setEditedStakefactor(props.selection)
		} else if (action == 'link') {
			setMode({ name: 'linking', origin: props.selection })
		}
	}

	const select = async (item, callback) => {
		if (mode.name == 'initial') {
			props.select(item, callback)
		} else if (mode.name == 'linking') {
			if (item && item.id && mode.origin && mode.origin.id && item.id !== mode.origin.id) {
				await handleCreateStakefactorLink(mode.origin, item)
				await props.select(item)
			}

			setMode({ name: 'initial' })
		}
	}

	return (
		<div className={'Navigator ' + mode.name + ' ' + (props.expanded ? 'expanded' : '')}>
			<HeaderPortal>
				{props.portalContent}
				<FontAwesomeIcon
					style={{ marginLeft: '0.5rem', marginRight: '0.5rem', cursor: 'pointer' }}
					icon={faInfoCircle}
					onClick={() => setVisibleHelpStep(initialStep)}
				/>
			</HeaderPortal>

			{props.displayConstraints && props.displayIndicators && (
				<SummaryColumn
					expanded={props.expanded}
					onIndicatorClick={(i: any) => {
						indicatorFilter == i.id ? setIndicatorFilter(null) : setIndicatorFilter(i.id)
						props.select(null)
					}}
					onConstraintClick={(i: any) => {
						constraintFilter == i.id ? setConstraintFilter(null) : setConstraintFilter(i.id)
						props.select(null)
					}}
					indicatorFilter={indicatorFilter}
					constraintFilter={constraintFilter}
					indicators={props.indicators}
					constraints={props.constraints}
					stakefactors={props.stakefactors}
					scenario={props.scenario}
					scenarios={props.scenarios}
					pushModal={props.pushModal}
					popModal={props.popModal}
				/>
			)}

			<div className="Body">
				{maxWidth && (
					<MainRadar
						offsetX={offsetX}
						offsetY={offsetY}
						width={maxWidth}
						height={maxWidth}
						selection={props.selection}
						select={select}
						mode={mode}
						scenario={props.scenario}
						deselect={props.deselect}
						onChangeItemPosition={handleChangeItemPosition}
						onDeleteLink={handleDeleteStakefactorLink}
						children={filteredStakefactors}
						onChange={handleChange}
						displayAllLinks={areAllLinksVisible}
						isTransitioning={isTransitioning}
						isLegendVisible={hasRadarLegend}
					></MainRadar>
				)}

				<TutorialPopover
					onClose={closeHelp}
					placement={'left'}
					style={{ maxWidth: '400px' }}
					visible={visibleHelpStep == 3}
					body={
						<>
							The gauge is regulated by an algorithm that takes into consideration all parameters that
							will be associated, individually and collectively, to the stakeholders. The gauge defines
							whether your stakeholders context is favorable or hostile to the achievement of your goal.
							By working on the stakeholders, you can directly influence the gauge
						</>
					}
					onNext={onNext}
					hasNext={hasNext}
				>
					<div className="GaugeWrapper">
						<RadialVulnerabilityGauge
							stakefactors={filteredStakefactors}
							scenario={props.scenario}
							scenarios={props.scenarios}
						></RadialVulnerabilityGauge>
					</div>

					{!props.scenario && (
						<AddButton
							id="AddItem"
							tooltip={t('models.item.actions.create_new')}
							onClick={() => {
								setEditedStakefactor({})
							}}
							className={opened ? 'opened' : ''}
							popover={
								<TutorialPopover
									onClose={closeHelp}
									placement={'left'}
									visible={visibleHelpStep == 1}
									body="Click here to add a stakeholder"
									onNext={onNext}
									hasNext={hasNext}
								></TutorialPopover>
							}
						></AddButton>
					)}
				</TutorialPopover>
			</div>

			<TutorialPopover
				onClose={closeHelp}
				placement={'left'}
				visible={visibleHelpStep == 2}
				body={
					<>
						This part allows you to filter the stakeholders
						<br />
						according to several criteria
					</>
				}
				onNext={onNext}
				hasNext={hasNext}
			>
				<aside className={'SideMenu noselection ' + (opened ? 'opened' : '')}>
					<ul>
						{false && (
							<li
								onClick={() => {
									setOpened(!opened)
								}}
								className="toggle"
							>
								<a>
									<FontAwesomeIcon icon={faTriangle} rotation={opened ? 180 : 0} />
								</a>
							</li>
						)}

						{props.selection ? (
							<>
								<li
									onClick={() => {
										if (!props.selection) return
										setSelectedTab('general')
										setOpened(true)
									}}
									className={[
										selectedTab == 'general' ? 'selected' : '',
										!props.selection ? 'disabled' : '',
									].join(' ')}
								>
									{t('pages.navigator.tabs.general.title')}
								</li>
								{!props.scenario && (
									<li
										onClick={() => {
											if (!props.selection) return
											setSelectedTab('links')
											setOpened(true)
										}}
										className={[
											selectedTab == 'links' ? 'selected' : '',
											!props.selection ? 'disabled' : '',
										].join(' ')}
									>
										{t('pages.navigator.tabs.links.title')}
									</li>
								)}
								{!props.scenario && (
									<li
										onClick={() => {
											if (!props.selection) return
											setSelectedTab('tags')
											setOpened(true)
										}}
										className={[
											selectedTab == 'tags' ? 'selected' : '',
											!props.selection ? 'disabled' : '',
										].join(' ')}
									>
										{t('pages.navigator.tabs.tags.title')}
									</li>
								)}
								{!props.scenario && (
									<li
										onClick={() => {
											if (!props.selection) return
											setSelectedTab('comments')
											setOpened(true)
										}}
										className={[
											selectedTab == 'comments' ? 'selected' : '',
											!props.selection ? 'disabled' : '',
										].join(' ')}
									>
										{t('pages.navigator.tabs.comments.title')}
									</li>
								)}
							</>
						) : (
							<>
								<li
									onClick={() => {
										setSelectedGlobalTab('filters')
										setOpened(true)
									}}
									className={[selectedGlobalTab == 'filters' ? 'selected' : ''].join(' ')}
								>
									{t('pages.navigator.tabs.filters.title')}
								</li>
							</>
						)}
					</ul>
					<div className="content h-100 pb-4 ">
						{props.selection ? (
							<>
								<div className="d-flex flex-row align-items-center">
									<div className="IconPicker" style={{ marginRight: '1rem' }}>
										<IconPicker
											value={props.selection?.icon || 'faUser'}
											favorites={allIcons}
											disabled={!!props.scenario}
											onChange={
												props.scenario
													? () => {}
													: (v) => handleChangeItemIcon(props.selection.id, v)
											}
										/>
									</div>

									<div>{props.selection?.name}</div>
								</div>

								{selectedTab == 'general' && (
									<GeneralTab
										selection={indexedStakefactors[props.selection?.id]}
										indicators={props.indicators}
										constraints={props.constraints}
										onEdit={handleEditStakefactor}
										onClone={(defaults) => handleCreateStakefactor({}, defaults)}
										onDelete={askDeleteConfirmation}
										scenario={props.scenario}
										displayConstraints={props.displayConstraints}
										displayIndicators={props.displayIndicators}
									></GeneralTab>
								)}

								{selectedTab == 'tags' && (
									<TagsTab
										onChangeItemTags={handleChangeItemTags}
										selection={props.selection}
										allTags={allTags}
										scenario={props.scenario}
									/>
								)}

								{selectedTab == 'links' && (
									<LinksTab
										indexedStakefactors={indexedStakefactors}
										stakefactors={props.stakefactors}
										onDeleteStakefactorLink={handleDeleteStakefactorLink}
										onChangeStakefactorLinkStrength={handleChangeStakefactorLinkStrength}
										onCreateLink={() => handleChange('link')}
										selection={props.selection}
										scenario={props.scenario}
									></LinksTab>
								)}

								{selectedTab == 'comments' && (
									<CommentsTab
										indexedStakefactors={indexedStakefactors}
										selection={props.selection}
										pushModal={props.pushModal}
										popModal={props.popModal}
										scenario={props.scenario}
									></CommentsTab>
								)}
							</>
						) : (
							<>
								<Input
									onChange={(e) => {
										props.setSearchTerm(e.target.value)
									}}
									value={props.searchTerm}
									style={{ marginLeft: 'auto', marginRight: '1em' }}
									placeholder="Search"
									className="SearchInput mt-2"
								></Input>

								<ul>
									<li className="EditableListItem nomargin mt-2">
										<div style={{ textAlign: 'left' }}>{t('pages.navigator.links')}</div>
										<Weight
											count={1}
											onClick={() => {
												setAreAllLinksVisible(1 - areAllLinksVisible)
											}}
										>
											{areAllLinksVisible ? 1 : 0}
										</Weight>
									</li>
								</ul>

								<div className="text-left" style={{ marginBottom: '0.5rem' }}>
									{t('pages.navigator.all_tags')}
								</div>

								{(allTags || []).length == 0 ? (
									<div style={{ color: 'rgba(46, 160, 223, 0.3)' }}>
										{t('pages.navigator.no_tag')}
									</div>
								) : (
									<div className="text-left">
										{allTags.map((t, index) => (
											<Tag
												key={'t' + index}
												onClick={() => {
													// Add filter if not present, remove it if already present
													tagFilter.includes(t)
														? setTagFilter(tagFilter.filter((tag) => tag !== t))
														: setTagFilter([...tagFilter, t])
													//tagFilter == t ? setTagFilter(null) : setTagFilter(t)
												}}
												className={['tag', tagFilter.includes(t) ? '' : 'unselected'].join(' ')}
											>
												{t}
											</Tag>
										))}
									</div>
								)}

								<div className="text-left mt-4" style={{ marginBottom: '0.5rem' }}>
									{t('pages.navigator.all_icons')}
								</div>

								{(allIcons || []).length == 0 ? (
									<div style={{ color: 'rgba(46, 160, 223, 0.3)' }}>
										{t('pages.navigator.no_icon')}
									</div>
								) : (
									<div className="text-left">
										{allIcons.map((i, index) => (
											<Tag
												key={'i' + index}
												onClick={() => {
													iconFilter == i ? setIconFilter(null) : setIconFilter(i)
												}}
												className={['tag pb-1', iconFilter == i ? '' : 'unselected'].join(' ')}
											>
												<FontAwesomeIcon
													style={{ fontSize: '1rem' }}
													icon={fal[i]}
												></FontAwesomeIcon>
											</Tag>
										))}
									</div>
								)}

								{props.displayIndicators && (
									<>
										<div
											className="text-left mt-4 d-flex align-items-center"
											style={{ marginBottom: '0.5rem' }}
										>
											<span className="text-nowrap" style={{ marginRight: '1rem' }}>
												{t('pages.navigator.all_indicators')}
											</span>
										</div>
										<div className="text-left">
											{props.indicators && props.indicators.length ? (
												sortBy(props.indicators || [], 'name').map((i: any, index: Number) => {
													return (
														<Tag
															key={'i' + index}
															onClick={() => {
																indicatorFilter == i.id
																	? setIndicatorFilter(null)
																	: setIndicatorFilter(i.id)
																props.select(null)
															}}
															className={[
																'tag ImpactedIndicator',
																indicatorFilter == i.id ? '' : 'unselected',
															].join(' ')}
														>
															{i.name}
														</Tag>
													)
												})
											) : (
												<div
													className="text-center"
													style={{ color: 'rgba(46, 160, 223, 0.3)' }}
												>
													{t('models.indicator.no_x')}
												</div>
											)}
										</div>
									</>
								)}

								{props.displayConstraints && (
									<>
										<div
											className="text-left mt-4 d-flex align-items-center"
											style={{ marginBottom: '0.5rem' }}
										>
											<span className="text-nowrap" style={{ marginRight: '1rem' }}>
												{t('pages.navigator.all_constraints')}
											</span>
										</div>
										<div className="text-left">
											{props.constraints && props.constraints.length ? (
												sortBy(props.constraints || [], 'name').map((i: any, index: Number) => {
													return (
														<Tag
															key={'c' + index}
															onClick={() => {
																constraintFilter == i.id
																	? setConstraintFilter(null)
																	: setConstraintFilter(i.id)
																props.select(null)
															}}
															className={[
																'tag ImpactedConstraint',
																constraintFilter == i.id ? '' : 'unselected',
															].join(' ')}
														>
															{i.name}
														</Tag>
													)
												})
											) : (
												<div
													className="text-center"
													style={{ color: 'rgba(46, 160, 223, 0.3)' }}
												>
													{t('models.constraint.no_x')}
												</div>
											)}
										</div>
									</>
								)}
							</>
						)}
					</div>
				</aside>
			</TutorialPopover>

			{editedStakefactor && (
				<StakefactorModal
					onHide={() => {
						setEditedStakefactor(null)
					}}
					onSave={(values: Object) => {
						editedStakefactor.id
							? handleChangeItem(editedStakefactor.id, {
									...pick(editedStakefactor, ['name', 'description', 'impact', 'trend', 'icon']),
									...values,
							  })
							: handleCreateStakefactor(
									{
										...values,
									},
									{}
							  )
						setEditedStakefactor(null)
					}}
					onDelete={() => {
						askDeleteConfirmation(editedStakefactor.id)
						setEditedStakefactor(null)
					}}
					id={editedStakefactor?.id}
					values={{
						name: editedStakefactor?.name,
						description: editedStakefactor?.description,
						impact: editedStakefactor?.impact,
					}}
				></StakefactorModal>
			)}
		</div>
	)
}

export default Navigator
