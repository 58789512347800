import React from 'react'
import { useAuth0 } from '../../react-auth0-spa'

import { Button, Layout } from 'antd'
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/pro-light-svg-icons'
import HeaderPortal from '../../layout/header/HeaderPortal'

import Loader from 'components/Loading/Loader'
import PlansList from 'components/Subscription/PlansList'

import './Profile.sass'

interface Props {
	returnTo: string
	paymentPublicKey: string
	isSubscriber: boolean
	subscriptionDetails: any
}

export const Profile = (props: Props) => {
	const { loading, user } = useAuth0()
	const { logout } = useAuth0()

	return (
		<div className="Profile">
			<HeaderPortal>My profile</HeaderPortal>

			{loading ? (
				<Loader></Loader>
			) : (
				<>
					<div className="infos">
						{user && (
							<>
								<img src={user.picture} alt="Profile" />
								<div>
									<h2>{user.name}</h2>
									<p>{user.email}</p>
								</div>
							</>
						)}
					</div>

					{!props.isSubscriber && process.env.REACT_APP_MODE !== 'corporate' ? (
						<>
							<div className="title">Choose a plan</div>
							<div className="subtitle">Unlock immediately all functionalities</div>
							<PlansList />
						</>
					) : (
						props.subscriptionDetails && (
							<div className="subscriptionDetails text-center m-auto mb-4">
								<small>Your current subscription</small>
								{props.subscriptionDetails.startAt && (
									<div className="mt-2">
										From {moment.unix(props.subscriptionDetails.startAt).format('LL')}
										{props.subscriptionDetails.endAt && (
											<>
												{' '}
												to {moment.unix(props.subscriptionDetails.endAt).format('LL')} (
												{moment.unix(props.subscriptionDetails.endAt).fromNow()})
											</>
										)}
									</div>
								)}
							</div>
						)
					)}
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Button
							onClick={(e) => {
								e.preventDefault()
								logout({ returnTo: props.returnTo })
							}}
						>
							<FontAwesomeIcon icon={faSignOut} /> &nbsp;Sign out
						</Button>
					</div>
				</>
			)}
		</div>
	)
}
