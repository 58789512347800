// @ts-nocheck
import React, { Component } from 'react'

import SelectionContext from './SelectionContext'

export default class SelectionProvider extends Component {
	select = (obj, callback) => {
		this.setState(
			{
				selection: obj,
			},
			callback
		)
	}

	deselect = (callback) => {
		this.setState(
			{
				selection: null,
			},
			callback
		)
	}

	state = {
		selection: null,
		select: this.select,
		deselect: this.deselect,
	}

	render() {
		return <SelectionContext.Provider value={this.state}>{this.props.children}</SelectionContext.Provider>
	}
}
