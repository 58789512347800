import { useTranslation } from 'react-i18next'

import { flatten } from 'lodash'
import { useSpring, animated, interpolate } from '@react-spring/web'

import { getIndicatorOrConstraintScore, scenarioScore, vulnerability } from 'utils/ranking'

import './SynthesisGraph.sass'
import { getItemAlteredByScenario } from 'utils/rules'
import { Tooltip } from 'antd'

const triangle = (size: number, color: string) => {
	const h = median(size)

	const points = [
		[-size / 2, h / 3],
		[size / 2, h / 3],
		[0, (-2 * h) / 3],
	]
	return (
		<polygon
			points={`${points[0].join(' ')}, ${points[1].join(' ')}, ${points[2].join(' ')}`}
			fill="none"
			stroke={color}
			strokeDasharray={'3 2'}
			strokeWidth={0.2}
		></polygon>
	)
}

const median = (size: number) => {
	return (Math.sqrt(3) / 2) * size
}

const getPoint = (value, angle) => {
	const rad = ((Math.PI * 2) / 360) * -angle
	const normalizedValue = ((value / 100) * median(100) * 2) / 3

	return [normalizedValue * Math.cos(rad), -normalizedValue * Math.sin(rad)]
}

const getPointsForScenario = (stakefactors, constraints, indicators, scenario) => {
	const adaptedStakefactors = stakefactors.map((s) => getItemAlteredByScenario(s, scenario))
	const adaptedConstraints = constraints.map((s) => getItemAlteredByScenario(s, scenario))
	const adaptedIndicators = indicators.map((s) => getItemAlteredByScenario(s, scenario))

	const computedIndicatorsVulnerability: any = getIndicatorOrConstraintScore(adaptedIndicators)
	const computedConstraintsVulnerability: any = getIndicatorOrConstraintScore(adaptedConstraints)

	const points = [
		getPoint(computedIndicatorsVulnerability, -90),
		getPoint(computedConstraintsVulnerability, 30),
		getPoint(100 - vulnerability(adaptedStakefactors), 150),
		getPoint(computedIndicatorsVulnerability, -90),
	]

	return points
}

export const SynthesisGraph = (props: any) => {
	const { t } = useTranslation()
	const centerY = (2 * median(100)) / 3

	console.log('elements', props.stakefactors, props.constraints, props.indicators)

	const mainPoints = getPointsForScenario(
		props.stakefactors.map((s) => (s && s.o ? s.o : s)),
		props.constraints.map((c) => (c && c.o ? c.o : c)),
		props.indicators.map((i) => (i && i.o ? i.o : i)),
		props.scenario
	)

	let score = scenarioScore(props, props.scenario)

	let allScenarios = props.scenarios ? [...props.scenarios] : []
	allScenarios.push({ id: null, name: null })

	return (
		<div className="SynthesisGraphWrapper">
			<Tooltip title="Scenario score">
				<div className="score">{score.toFixed(0)}</div>
			</Tooltip>

			<div className="indicators">{t('models.indicator.name_plural')}</div>
			<div className="stakefactors">{t('models.item.name_plural')}</div>
			<div className="constraints">{t('models.constraint.name_plural')}</div>

			<svg className="SynthesisGraph" viewBox={`0 0 100 ${median(100)}`}>
				<g style={{ transformOrigin: '50% 50%' }} transform={`translate(${50} ${centerY} )`}>
					{triangle(100, '#00B0A9')}
					{triangle(50, '#E70549')}
				</g>

				{allScenarios.map((s) => {
					let points = getPointsForScenario(
						props.stakefactors.map((st) => (st && st.o ? st.o : st)),
						props.constraints.map((c) => (c && c.o ? c.o : c)),
						props.indicators.map((i) => (i && i.o ? i.o : i)),
						s
					)
					return (
						<g key={s.id} transform={`translate( 50, ${(2 * median(100)) / 3} )`}>
							<path
								fill="none"
								strokeWidth={0.3}
								stroke={'#DDDDDD30'}
								d={'M' + points.map((p) => p.join(' ')).join(' L')}
							></path>
							{points.map((p, index) => {
								return (
									<Tooltip key={index} title={<div>{s.name || 'Baseline'}</div>}>
										<circle cx={p[0]} cy={p[1]} r={3} fill="#00000000"></circle>
									</Tooltip>
								)
							})}
						</g>
					)
				})}

				<g transform={`translate( 50, ${(2 * median(100)) / 3} )`}>
					<path
						fill="none"
						strokeWidth={0.5}
						stroke={'white'}
						d={'M' + mainPoints.map((p) => p.join(' ')).join(' L')}
					></path>
					{mainPoints.map((p, index) => {
						return (
							<Tooltip key={index} title={<div>{props.scenario?.name || 'Baseline'}</div>}>
								<circle cx={p[0]} cy={p[1]} r={3} fill="#00000000"></circle>
							</Tooltip>
						)
					})}
				</g>
			</svg>
		</div>
	)
}
