import { sortBy, findIndex } from 'lodash'

import moment from 'moment'

import EditableListItem from 'components/List/EditableListItem'

import './CommentsList.sass'

//onDelete={() => props.onDelete(o.id)}

export const CommentsList = (props: any) => {
	return (
		<div className="CommentsList mb-3 w-100">
			{sortBy(props.items || [], (o) => -moment(o.commentedAt).unix()).map((o: any, index: number) => (
				<EditableListItem
					key={index}
					deletable={true}
					editable={true}
					key={o.id}
					onEdit={() => props.onEdit(o.id)}
				>
					<div className="pb-2 pt-2">
						<small>
							{o.commentedAt
								? moment(o.commentedAt).format('DD.MM.YYYY')
								: moment(o.createdAt).format('DD.MM.YYYY')}
						</small>
						&nbsp;{' - '}&nbsp;
						{o.description}
					</div>
				</EditableListItem>
			))}
		</div>
	)
}

export default CommentsList
