import React from 'react'

import { withTranslation } from 'react-i18next'

import { sumBy } from 'lodash'
import { vulnerabilityConstraints } from 'utils/rules'
import { Tooltip } from 'antd'

import('./HorizontalVulnerabilityScale.sass')

class HorizontalVulnerabilityScale extends React.Component {
	render() {
		const { value, t } = this.props
		const innerWidth = 200

		return (
			<div className="HorizontalVulnerabilityScale" style={{ width: '300px' }}>
				<div className="top noselection">{t('pages.scenarios.context_best')}</div>
				<div className="middle">
					<hr />
				</div>
				<div className="bottom noselection">{t('pages.scenarios.context_worse')}</div>

				{value !== null && value !== undefined ? (
					<div id="bar_marker" style={{ left: value + '%' }}></div>
				) : null}

				{this.props.vulnerabilities.map((v) => (
					<Tooltip placement="top" key={v.id} title={<div>{v.name || 'Baseline'}</div>}>
						<div id={'initial_bar_marker'} style={{ left: v.vulnerability + '%' }}></div>
					</Tooltip>
				))}
			</div>
		)
	}
}

export default withTranslation()(HorizontalVulnerabilityScale)
