import React, { useCallback, useEffect } from 'react'

import { useAuth0 } from '../../react-auth0-spa'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faClone,
	faExclamationTriangle,
	faInfoCircle,
	faSignIn,
	faTrash,
	faUser,
} from '@fortawesome/pro-light-svg-icons'

import HeaderPortal from '../../layout/header/HeaderPortal'
import { ProjectsList } from './ProjectsList'
import AddButton from 'components/action/AddButton'
import Loader from 'components/Loading/Loader'
import ProjectModal from 'components/Modal/ProjectModal'

// Help
import TutorialPopover from 'components/Help/TutorialPopover'
import { getNextStep, getInitialStep } from 'help/projects'

import {
	OnCreateProjectSubscription,
	useCreateProjectMutation,
	useDeleteProjectMutation,
	useListProjectsQuery,
	ListProjectsDocument,
	useOnCreateProjectSubscription,
	useGetSubscriptionStatusQuery,
	useConsumeCodeMutation,
} from '../../graphql/generated'

import './Projects.sass'

export const Projects = (props: any) => {
	const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
	const [editedItem, setEditedItem] = React.useState<any | null>(null)
	const [createProject] = useCreateProjectMutation()
	const [consumeCode] = useConsumeCodeMutation()
	const { data, refetch, loading } = useListProjectsQuery({
		variables: { u: localStorage.getItem('u') },
	})

	const avoidSubscriptions = process.env.REACT_APP_MODE !== 'stakeholder'

	const getSubscription = useGetSubscriptionStatusQuery()
	const { t } = useTranslation()
	let history = useHistory()

	// Help
	const [visibleHelpStep, setVisibleHelpStep] = React.useState<any | null>(null)
	const closeHelp = () => setVisibleHelpStep(null)
	const initialStep = getInitialStep(data?.listProjects?.items, props.isSubscriber)
	const nextStep = getNextStep(visibleHelpStep, data?.listProjects?.items, props.isSubscriber)
	const onNext = () => setVisibleHelpStep(nextStep)
	const hasNext = !!nextStep

	const hasLessThan2Projects = data && data.listProjects && data.listProjects?.items?.length < 1
	const hasLessThanAuthorizedProjects =
		((data && data.listProjects && data.listProjects?.items?.length) || 0) < props.maxProjectsCount

	const canCreateProjects = avoidSubscriptions || hasLessThanAuthorizedProjects || hasLessThan2Projects

	useEffect(() => {
		if (props.setExpanded) props.setExpanded(false)

		if (localStorage.getItem('gerositusCode')) {
			let code = localStorage.getItem('gerositusCode')
			console.log('gerositusCode found', localStorage.getItem('gerositusCode'))
			consumeCodeAndUpdateSubscription(code)
		}
	}, [])

	const handleCreateProject = async (values: any) => {
		const result = await createProject({
			variables: {
				...values,
			},
		})
		await refetch()
		history.push('/projects/' + result?.data?.createProject?.id)
	}

	const handleSelectProject = async (project: any) => {
		history.push('/projects/' + project.id)
	}

	const consumeCodeAndUpdateSubscription = async (code) => {
		await consumeCode({ variables: { id: code } })
		await getSubscription.refetch()
		localStorage.removeItem('gerositusCode')
	}

	// Update subscription details if needed
	if (!avoidSubscriptions) {
		if (!props.subscriptionDetails && getSubscription.data?.getSubscriptionStatus?.email) {
			console.log(props, getSubscription.data.getSubscriptionStatus)
			props.setSubscriptionDetails(getSubscription.data.getSubscriptionStatus)
		}
	}

	return (
		<div className="Projects">
			<HeaderPortal>
				{t('pages.projects.title')}
				{props.isFeatureEnabledProfile && (
					<FontAwesomeIcon
						style={{ marginLeft: 'auto', marginRight: '1rem', cursor: 'pointer' }}
						icon={faInfoCircle}
						onClick={() => setVisibleHelpStep(initialStep)}
					/>
				)}
				{props.isFeatureEnabledProfile && (
					<TutorialPopover
						onClose={closeHelp}
						placement={'left'}
						visible={visibleHelpStep == 2}
						body="Edit your profile and/or sign out"
						onNext={onNext}
						hasNext={hasNext}
					>
						{!isAuthenticated ? (
							<>
								<a
									onClick={(e) => {
										e.preventDefault()
										loginWithRedirect()
									}}
								>
									<FontAwesomeIcon icon={faSignIn} />
								</a>
							</>
						) : (
							<>
								<a
									onClick={() => {
										history.push('/profile')
									}}
								>
									<FontAwesomeIcon icon={faUser} />
								</a>
							</>
						)}
					</TutorialPopover>
				)}
			</HeaderPortal>

			{!avoidSubscriptions && (loading || getSubscription.loading) ? (
				<Loader></Loader>
			) : (
				<>
					<ProjectsList
						pushModal={props.pushModal}
						popModal={props.popModal}
						onSelectProject={handleSelectProject}
						canClone={canCreateProjects}
						list={data}
						tutorialPopover={
							<TutorialPopover
								onClose={closeHelp}
								placement="bottom"
								onNext={onNext}
								hasNext={hasNext}
								visible={visibleHelpStep == 3}
								body={
									<>
										Click to open an existing stakeholder map.
										<br />
										You can delete it by clicking on <FontAwesomeIcon icon={faTrash} />
										<br />
										You can clone it by clicking on <FontAwesomeIcon icon={faClone} />
									</>
								}
							></TutorialPopover>
						}
					></ProjectsList>
					{canCreateProjects ? (
						<div className="footer">
							<AddButton
								onClick={() => {
									setEditedItem({})
								}}
								popover={
									<TutorialPopover
										onClose={closeHelp}
										placement="top"
										onNext={onNext}
										hasNext={hasNext}
										visible={visibleHelpStep == 1}
										body="Create a new stakeholder map"
									></TutorialPopover>
								}
							></AddButton>{' '}
						</div>
					) : (
						!props.isSubscriber && (
							<div className="subscribeNow" onClick={() => history.push('/profile')}>
								{' '}
								Welcome and thank you for connecting to Gerositus Stakeholders Management! <br />
								<u>Subscribe now</u> to be able to create new stakeholder maps.
								<br />
								Upon subscription, you have 7 days of free trial.
							</div>
						)
					)}
				</>
			)}

			{editedItem !== null && (
				<ProjectModal
					onHide={() => {
						setEditedItem(null)
					}}
					onSave={(values: Object) => {
						handleCreateProject(values)
						setEditedItem(null)
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						description: editedItem?.description,
						descriptionLong: editedItem?.descriptionLong,
					}}
				></ProjectModal>
			)}
		</div>
	)
}
