import React, { Component } from 'react'

import { keyBy, sortBy, find } from 'lodash'
import { useTranslation } from 'react-i18next'
import gql from 'graphql-tag'

import { Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faEdit, faTrash } from '@fortawesome/pro-light-svg-icons'

import ActionButton from 'components/action/ActionButton'

import { itemActiveness } from 'components/Radar/Radar'

import {
	useCreateStakefactorIndicatorImpactMutation,
	useUpdateStakefactorIndicatorImpactStrengthMutation,
	useDeleteStakefactorIndicatorImpactMutation,
	useCreateStakefactorConstraintImpactMutation,
	useUpdateStakefactorConstraintImpactStrengthMutation,
	useDeleteStakefactorConstraintImpactMutation,
} from '../../../../graphql/generated'
import Weight from 'components/controls/Weight'
import { getScoreColor } from 'utils/ranking'

// Props
interface GeneralTabProps {
	selection: any
	indicators: any
	constraints: any
	onEdit: any
	onClone: any
	onDelete: any
	scenario: any
	displayConstraints: boolean
	displayIndicators: boolean
}

export const GeneralTab = (props: GeneralTabProps) => {
	const { t } = useTranslation()

	const indexedIndicators = keyBy(props.indicators, 'id')

	const isImpactingIndicator = (indicatorId: string) => {
		if (
			indexedIndicators[indicatorId] &&
			props.selection?.impactedIndicators?.map((ii: any) => ii.indicatorId).includes(indicatorId)
		)
			return true
		return false
	}

	const getImpactingIndicatorDetails = (indicatorId: string) => {
		return find(props.selection?.impactedIndicators || [], (ii: any) => ii.indicatorId == indicatorId)
	}

	// Stakefactor links
	const [createStakefactorIndicatorImpact] = useCreateStakefactorIndicatorImpactMutation()
	const [deleteStakefactorIndicatorImpact] = useDeleteStakefactorIndicatorImpactMutation()
	const [updateStakefactorIndicatorImpactStrengthMutation] = useUpdateStakefactorIndicatorImpactStrengthMutation()

	// Create
	const handleCreateStakefactorIndicatorImpact = async (stakefactorId, indicatorId) => {
		let result = await createStakefactorIndicatorImpact({
			variables: {
				stakefactorId,
				indicatorId,
			},
			update(cache, { data: { createStakefactorIndicatorImpact } }) {
				cache.modify({
					id: `Stakefactor:${stakefactorId}`,
					fields: {
						impactedIndicators(existingRefs, { readField }) {
							const newStakefactorIndicatorImpact = cache.writeFragment({
								id: 'StakefactorIndicatorImpact:' + createStakefactorIndicatorImpact.id,
								data: createStakefactorIndicatorImpact,
								fragment: gql`
									fragment StakefactorIndicatorImpactFragment on StakefactorIndicatorImpact {
										stakefactorId
										indicatorId
									}
								`,
							})
							return [...existingRefs, newStakefactorIndicatorImpact]
						},
					},
				})
			},

			optimisticResponse: {
				createStakefactorIndicatorImpact: {
					__typename: 'StakefactorIndicatorImpact',
					id: 'temp-id',
					stakefactorId,
					indicatorId,
				},
			},
		})
	}

	// Delete
	const handleDeleteStakefactorIndicatorImpact = async (stakefactorId: string, id: string) => {
		console.log('handleDeleteStakefactorIndicatorImpact', id)

		await deleteStakefactorIndicatorImpact({
			variables: {
				id,
			},

			update(cache, { data: { deleteStakefactorIndicatorImpact } }) {
				cache.modify({
					id: `Stakefactor:${stakefactorId}`,
					fields: {
						impactedIndicators(existingRefs, { readField }) {
							return existingRefs.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteStakefactorIndicatorImpact: {
					__typename: 'StakefactorIndicatorImpact',
					id: id,
				},
			},
		})
	}

	const handleChangeStakefactorIndicatorImpactStrength = (indicatorImpact: any, strength: number) => {
		console.log('handleChangeStakefactorIndicatorImpactStrength', strength)

		updateStakefactorIndicatorImpactStrengthMutation({
			variables: { id: indicatorImpact.id, strength },
			update(cache, { data: { updateStakefactorIndicatorImpactStrength } }) {
				cache.modify({
					id: `StakefactorIndicatorImpact:${updateStakefactorIndicatorImpactStrength.id}`,
					fields: {
						strength() {
							return strength
						},
					},
				})
			},

			optimisticResponse: {
				updateStakefactorIndicatorImpactStrength: {
					__typename: 'StakefactorIndicatorImpact',
					id: indicatorImpact.id,
					strength: strength,
				},
			},
		})
	}

	// Constraints

	const indexedConstraints = keyBy(props.constraints, 'id')

	const isImpactingConstraint = (constraintId: string) => {
		if (
			indexedConstraints[constraintId] &&
			props.selection?.impactedConstraints?.map((ii: any) => ii.constraintId).includes(constraintId)
		)
			return true
		return false
	}

	const getImpactingConstraintDetails = (constraintId: string) => {
		return find(props.selection?.impactedConstraints || [], (ii: any) => ii.constraintId == constraintId)
	}

	// Stakefactor links
	const [createStakefactorConstraintImpact] = useCreateStakefactorConstraintImpactMutation()
	const [deleteStakefactorConstraintImpact] = useDeleteStakefactorConstraintImpactMutation()
	const [updateStakefactorConstraintImpactStrengthMutation] = useUpdateStakefactorConstraintImpactStrengthMutation()

	// Create
	const handleCreateStakefactorConstraintImpact = async (stakefactorId, constraintId) => {
		let result = await createStakefactorConstraintImpact({
			variables: {
				stakefactorId,
				constraintId,
			},
			update(cache, { data: { createStakefactorConstraintImpact } }) {
				cache.modify({
					id: `Stakefactor:${stakefactorId}`,
					fields: {
						impactedConstraints(existingRefs, { readField }) {
							const newStakefactorConstraintImpact = cache.writeFragment({
								id: 'StakefactorConstraintImpact:' + createStakefactorConstraintImpact.id,
								data: createStakefactorConstraintImpact,
								fragment: gql`
									fragment StakefactorConstraintImpactFragment on StakefactorConstraintImpact {
										stakefactorId
										constraintId
									}
								`,
							})
							return [...existingRefs, newStakefactorConstraintImpact]
						},
					},
				})
			},

			optimisticResponse: {
				createStakefactorConstraintImpact: {
					__typename: 'StakefactorConstraintImpact',
					id: 'temp-id',
					stakefactorId,
					constraintId,
				},
			},
		})
	}

	// Delete
	const handleDeleteStakefactorConstraintImpact = async (stakefactorId: string, id: string) => {
		console.log('handleDeleteStakefactorConstraintImpact', id)

		await deleteStakefactorConstraintImpact({
			variables: {
				id,
			},

			update(cache, { data: { deleteStakefactorConstraintImpact } }) {
				cache.modify({
					id: `Stakefactor:${stakefactorId}`,
					fields: {
						impactedConstraints(existingRefs, { readField }) {
							return existingRefs.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteStakefactorConstraintImpact: {
					__typename: 'StakefactorConstraintImpact',
					id: id,
				},
			},
		})
	}

	const handleChangeStakefactorConstraintImpactStrength = (constraintImpact: any, strength: number) => {
		console.log('handleChangeStakefactorConstraintImpactStrength', strength)

		updateStakefactorConstraintImpactStrengthMutation({
			variables: { id: constraintImpact.id, strength },
			update(cache, { data: { updateStakefactorConstraintImpactStrength } }) {
				cache.modify({
					id: `StakefactorConstraintImpact:${updateStakefactorConstraintImpactStrength.id}`,
					fields: {
						strength() {
							return strength
						},
					},
				})
			},

			optimisticResponse: {
				updateStakefactorConstraintImpactStrength: {
					__typename: 'StakefactorConstraintImpact',
					id: constraintImpact.id,
					strength: strength,
				},
			},
		})
	}

	return (
		<>
			<div className="d-flex flex-column flex-grow">
				<div className="text-left mt-2">
					{(props.selection?.tags || []).map((t, index) => (
						<Tag color={'#363D4D'} style={{ color: '#A6B9C8' }} className="tag">
							{t}
						</Tag>
					))}
				</div>

				<ul>
					<li className="EditableListItem">
						<div style={{ width: '35%', textAlign: 'left' }}>Activeness</div>
						<div>
							{props.selection?.impact
								? {
										low: 'Low',
										medium: 'Medium',
										high: 'High',
										likely: 'Very Low',
										undefined: 'Neutral',
								  }[itemActiveness(props.selection?.x, props.selection?.y)]
								: null}{' '}
							{props.selection?.y > 0 ? 'positive' : 'negative'}
						</div>
					</li>

					<li className="EditableListItem">
						<div style={{ width: '35%', textAlign: 'left' }}>Power</div>
						<div>
							{props.selection?.impact
								? {
										low: 'Low',
										medium: 'Medium',
										high: 'High',
								  }[props.selection.impact]
								: 'Low'}
						</div>
					</li>

					<li className="EditableListItem">
						<div style={{ width: '35%', textAlign: 'left' }}>Trust</div>
						<div>
							{props.selection?.trend
								? {
										green: 'Positive',
										orange: 'At risk',
										neutral: 'Neutral',
										red: 'Negative',
								  }[props.selection?.trend]
								: 'Neutral'}
						</div>
					</li>
					{props.selection?.description && (
						<li className="EditableListItem">
							<div>{props.selection?.description}</div>
						</li>
					)}
				</ul>

				{!props.scenario && (
					<div className="Actions mt-2 mb-4">
						<ActionButton
							className="small"
							tooltip={t('models.item.actions.edit')}
							onClick={() => props.onEdit(props.selection.id)}
						>
							<FontAwesomeIcon icon={faEdit} />
						</ActionButton>

						<ActionButton
							className="small"
							tooltip={t('models.item.actions.clone')}
							onClick={() => props.onClone(props.selection)}
						>
							<FontAwesomeIcon icon={faClone} />
						</ActionButton>

						<ActionButton
							className="small"
							tooltip={t('models.item.actions.delete')}
							onClick={() => props.onDelete(props.selection.id)}
						>
							<FontAwesomeIcon icon={faTrash} />
						</ActionButton>
					</div>
				)}

				{props.displayIndicators && (
					<div className="d-flex flex-column flex-grow mt-3 mb-4">
						<div className="text-left mb-2">{t('models.item.fields.indicators.name')}</div>
						<div className="text-left">
							{props.indicators && props.indicators.length ? (
								sortBy(props.indicators || [], 'name').map((i: any, index) => {
									const impactedIndicator = getImpactingIndicatorDetails(i.id)

									return (
										<Tag
											key={index}
											onClick={() => {
												console.log('impactedIndicator', impactedIndicator)

												if (props.scenario) return true

												if (!impactedIndicator || !impactedIndicator.id) {
													handleCreateStakefactorIndicatorImpact(props.selection?.id, i.id)
												} else if (impactedIndicator.strength < 3) {
													handleChangeStakefactorIndicatorImpactStrength(
														impactedIndicator,
														impactedIndicator.strength + 1
													)
												} else {
													handleDeleteStakefactorIndicatorImpact(
														props.selection?.id,
														impactedIndicator.id
													)
												}
											}}
											className={[
												'ImpactedIndicator tag',
												isImpactingIndicator(i.id) ? 'selected' : 'unselected',
											].join(' ')}
										>
											<div
												className={'Trend v' + i.trend}
												style={i.trend == '50' ? {} : { background: getScoreColor(i.trend) }}
											></div>
											{i.name}

											{impactedIndicator ? (
												<Weight onClick={() => {}}>{impactedIndicator.strength}</Weight>
											) : null}
										</Tag>
									)
								})
							) : (
								<div className="text-center" style={{ color: 'rgba(46, 160, 223, 0.3)' }}>
									{t('models.indicator.no_x')}
								</div>
							)}
						</div>
					</div>
				)}

				{props.displayConstraints && (
					<div className="d-flex flex-column flex-grow mt-3 mb-4">
						<div className="text-left mb-2">{t('models.item.fields.constraints.name')}</div>
						<div className="text-left">
							{props.constraints && props.constraints.length ? (
								sortBy(props.constraints || [], 'name').map((i: any) => {
									const impactedConstraint = getImpactingConstraintDetails(i.id)

									return (
										<Tag
											onClick={() => {
												console.log('impactedConstraint', impactedConstraint)

												if (props.scenario) return true

												if (!impactedConstraint || !impactedConstraint.id) {
													handleCreateStakefactorConstraintImpact(props.selection?.id, i.id)
												} else if (impactedConstraint.strength < 3) {
													handleChangeStakefactorConstraintImpactStrength(
														impactedConstraint,
														impactedConstraint.strength + 1
													)
												} else {
													handleDeleteStakefactorConstraintImpact(
														props.selection?.id,
														impactedConstraint.id
													)
												}
											}}
											className={[
												'ImpactedConstraint tag',
												isImpactingConstraint(i.id) ? 'selected' : 'unselected',
											]}
										>
											<div
												className={'Trend v' + i.trend}
												style={i.trend == '50' ? {} : { background: getScoreColor(i.trend) }}
											></div>
											{i.name}

											{impactedConstraint ? (
												<Weight onClick={() => {}}>{impactedConstraint.strength}</Weight>
											) : null}
										</Tag>
									)
								})
							) : (
								<div className="text-center" style={{ color: 'rgba(46, 160, 223, 0.3)' }}>
									{t('models.constraint.no_x')}
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default GeneralTab
