import React from 'react'

import { withTranslation } from 'react-i18next'

import { sumBy } from 'lodash'
import { Tooltip } from 'antd'
import { getIndicatorOrConstraintScore } from 'utils/ranking'
import { getItemAlteredByScenario } from 'utils/rules'

import('./MiniConstraintVulnerabilityScale.sass')

class MiniConstraintVulnerabilityScale extends React.Component {
	render() {
		const { value, t } = this.props
		const innerWidth = 200

		let vulnerabilities = (this.props.scenarios || []).map((sc) => {
			return {
				id: sc.id,
				name: sc.name,
				vulnerability: getIndicatorOrConstraintScore(
					this.props.constraints.map((s) => getItemAlteredByScenario(s, sc))
				),
			}
		})

		vulnerabilities.push({
			id: null,
			vulnerability: getIndicatorOrConstraintScore(this.props.constraints.map((s) => (s && s.o ? s.o : s))),
		})

		if (!this.props.constraints || this.props.constraints.length == 0) vulnerabilities = []

		return (
			<div className="MiniConstraintVulnerabilityScale" style={{ width: '250px' }}>
				{vulnerabilities.map((v) => (
					<Tooltip placement="top" key={v.id} title={<div>{v.name || 'Baseline'}</div>}>
						<div
							id={v.id == this.props.scenario?.id ? 'bar_marker' : 'initial_bar_marker'}
							style={{ left: v.vulnerability + '%' }}
						></div>
					</Tooltip>
				))}
			</div>
		)
	}
}

export default withTranslation()(MiniConstraintVulnerabilityScale)
