import { useTranslation } from 'react-i18next'

import gql from 'graphql-tag'
import { keyBy } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import ActionButton from 'components/action/ActionButton'

import {
	useCreateStakefactorCommentMutation,
	useUpdateStakefactorCommentMutation,
	useDeleteStakefactorCommentMutation,
} from '../../../../graphql/generated'
import CommentsList from '../CommentsList'
import StakefactorCommentModal from 'components/Modal/StakefactorCommentModal'

export const CommentsTab = (props: any) => {
	const { t } = useTranslation()

	const [createStakefactorComment] = useCreateStakefactorCommentMutation()
	const [updateStakefactorComment] = useUpdateStakefactorCommentMutation()
	const [deleteStakefactorComment] = useDeleteStakefactorCommentMutation()

	const comments =
		props.indexedStakefactors && props.indexedStakefactors[props.selection.id]
			? props.indexedStakefactors[props.selection.id].comments.map((c: any) => {
					return {
						...c,
						name: c.description,
					}
			  })
			: []
	const indexedComments = keyBy(comments, 'id')

	// Create
	const handleCreateStakefactorComment = async (stakefactorId: string, description: string) => {
		props.pushModal(
			<StakefactorCommentModal
				onSave={async (values) => {
					console.log('onChange test', values)

					let variables = {
						stakefactorId,
						description: values.description,
						commentedAt: values.commentedAt,
					}
					let result = await createStakefactorComment({
						variables,
						update(cache, { data: { createStakefactorComment } }) {
							cache.modify({
								id: `Stakefactor:${stakefactorId}`,
								fields: {
									comments(existingStakefactorsLinksRef, { readField }) {
										const newStakefactorComment = cache.writeFragment({
											id: 'StakefactorComment:' + createStakefactorComment.id,
											data: createStakefactorComment,
											fragment: gql`
												fragment StakefactorFragment on Stakefactor {
													stakefactorId
													id
													description
													createdAt
													commentedAt
												}
											`,
										})
										return [...existingStakefactorsLinksRef, newStakefactorComment]
									},
								},
							})
						},

						optimisticResponse: {
							createStakefactorComment: {
								__typename: 'StakefactorComment',
								id: 'temp-id',
								...variables,
							},
						},
					})
				}}
			></StakefactorCommentModal>
		)
	}

	// Create
	const handleEditStakefactorComment = async (stakefactorId: string, id: string) => {
		console.log('Comment', indexedComments[id])

		props.pushModal(
			<StakefactorCommentModal
				values={indexedComments[id] || {}}
				onDelete={() => handleDeleteStakefactorComment(stakefactorId, id)}
				onSave={async (values) => {
					console.log('onChange test', values)

					let variables = {
						stakefactorId,
						id,
						description: values.description,
						commentedAt: values.commentedAt.format('YYYY-MM-DD'),
					}
					let result = await updateStakefactorComment({
						variables,
						update(cache, { data: { updateStakefactorComment } }) {
							cache.modify({
								id: `Stakefactor:${updateStakefactorComment.id}`,
								fields: {
									description() {
										return updateStakefactorComment.description
									},
									commentedAt() {
										return updateStakefactorComment.commentedAt
									},
								},
							})
						},

						optimisticResponse: {
							updateStakefactorComment: {
								__typename: 'StakefactorComment',
								...variables,
							},
						},
					})
				}}
			></StakefactorCommentModal>
		)
	}

	// Delete
	const handleDeleteStakefactorComment = async (stakefactorId: string, id: string) => {
		console.log('handleDeleteStakefactorComment', id)

		await deleteStakefactorComment({
			variables: {
				stakefactorId,
				id,
			},

			update(cache, { data: { deleteStakefactorComment } }) {
				cache.modify({
					id: `Stakefactor:${stakefactorId}`,
					fields: {
						comments(existingCommentsRef, { readField }) {
							return existingCommentsRef.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteStakefactorComment: {
					__typename: 'StakefactorComment',
					id: id,
				},
			},
		})
	}

	//onDelete={(id: string) => handleDeleteStakefactorComment(props.selection.id, id)}
	return (
		<>
			<div className="d-flex flex-column flex-grow mt-3">
				{props &&
					(!props.indexedStakefactors[props.selection.id].comments ||
						props.indexedStakefactors[props.selection.id].comments.length == 0) && (
						<div style={{ color: 'rgba(46, 160, 223, 0.25)' }}>{t('models.item_comment.no_x')}</div>
					)}

				<CommentsList
					onEdit={(id: string) => handleEditStakefactorComment(props.selection.id, id)}
					items={comments}
				></CommentsList>

				{!props.scenario && (
					<div className="Actions mt-4">
						<ActionButton
							className="small"
							tooltip={t('models.item.actions.edit')}
							onClick={() => handleCreateStakefactorComment(props.selection.id, 'test')}
						>
							<FontAwesomeIcon icon={faPlus} />
						</ActionButton>
					</div>
				)}
			</div>
		</>
	)
}

export default CommentsTab
