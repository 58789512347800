import React, { Component } from 'react'

import { keys, isObject } from 'lodash'

import { Modal, Button, Form, Input, Slider, DatePicker } from 'antd'
import moment from 'moment'

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24,
	},
}

export const FishboneItemEditionModal = (props: any) => {
	const [form] = Form.useForm()

	const handleFormSubmit = () => {
		console.log('handleFormSubmit FishboneItemEditionModal')
		form.validateFields()
			.then((values) => {
				console.log('validated', values)
				props.onSave(values)
				props.onHide()
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo)
			})
	}

	const footer = (
		<div style={{ display: 'flex' }}>
			{props.onDelete && (
				<Button
					onClick={() => {
						props.onDelete(props)
					}}
					danger={true}
					style={{ marginRight: 'auto' }}
				>
					Delete
				</Button>
			)}
			<Button
				onClick={() => {
					props.onHide()
				}}
				style={{ marginRight: 'auto' }}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	)

	const isDisabledDate = (current) => {
		if (props.minDate && moment(current).isBefore(props.minDate)) return true
		if (props.maxDate && moment(current).isAfter(props.maxDate)) return true
		return false
	}

	return (
		<Modal
			open={true}
			title={props.id ? 'Sub-bone edition' : 'Create a new sub-bone'}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
		>
			<Form
				{...formLayout}
				form={form}
				onFinish={(values) => {
					props.onChange(props.id, values)
					props.onHide()
				}}
				initialValues={props.values}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[{ max: 30, message: 'Please choose a shorter name (30 characters max)' }]}
				>
					<Input></Input>
				</Form.Item>
				<Form.Item name="responsible" label="Responsible name">
					<Input></Input>
				</Form.Item>
				<Form.Item name="description" label="Description">
					<Input.TextArea rows={5}></Input.TextArea>
				</Form.Item>
				<div className="d-flex">
					<Form.Item name="startOn" label="Start">
						<DatePicker disabledDate={isDisabledDate} format="L"></DatePicker>
					</Form.Item>
					<Form.Item name="endOn" label="End">
						<DatePicker disabledDate={isDisabledDate} format="L"></DatePicker>
					</Form.Item>
				</div>

				<Form.Item name="weight" label="Weight">
					<Slider tooltipVisible={true} tooltipPlacement="bottom" dots={true} min={1} max={3} />
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default FishboneItemEditionModal
