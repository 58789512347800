import React, { Component } from 'react'

import SubscriptionContext from './SubscriptionContext'

export default class SubscriptionProvider extends Component {
	setSubscriptionDetails = (subscriptionDetails) => {
		const isFree = process.env.REACT_APP_MODE === 'insiderisk' || process.env.REACT_APP_MODE == 'corporate'

		this.setState({
			subscriptionDetails,
			isSubscriber: isFree ? true : subscriptionDetails && subscriptionDetails.status === 'active',
			maxProjectsCount: isFree
				? 10000
				: subscriptionDetails && subscriptionDetails.projects
				? subscriptionDetails.projects
				: 1,
			features: {
				constraints: isFree ? true : false,
				indicators: isFree ? true : false,
				fishbone: isFree ? true : false,
				displayAdvancedScenarios: isFree ? true : false,
			},
		})
	}

	state = {
		subscriptionDetails: null,
		isSubscriber: false,
		setSubscriptionDetails: this.setSubscriptionDetails,
	}

	render() {
		return <SubscriptionContext.Provider value={this.state}>{this.props.children}</SubscriptionContext.Provider>
	}
}
