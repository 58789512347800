import React from 'react'

import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone, faTrash } from '@fortawesome/pro-light-svg-icons'

import ActionButton from 'components/action/ActionButton'

import './ProjectItem.sass'

export const ProjectItem = (props: any) => {
	const { t } = useTranslation()

	return (
		<div className="ProjectItem">
			<div onClick={(e: any) => props.onClick(props?.details)}>
				{props?.details?.name}

				<div>
					<small>Created on {moment(props?.details?.createdAt).format('LLL')}</small>
				</div>
			</div>

			<ActionButton
				tooltip={t('models.project.actions.delete')}
				className="action xsmall delete"
				onClick={props.onDelete}
			>
				<FontAwesomeIcon icon={faTrash} />
			</ActionButton>

			{props.onClone && (
				<ActionButton
					tooltip={t('models.project.actions.clone')}
					className="action xsmall clone"
					onClick={props.onClone}
				>
					<FontAwesomeIcon icon={faClone} />
				</ActionButton>
			)}
		</div>
	)
}

export default ProjectItem
