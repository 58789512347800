import { MiniSlider } from 'components/controls/MiniSlider'

import Weight from 'components/controls/Weight'
import { filter } from 'lodash'
import { getItemAlteredByScenario } from 'utils/rules'

import './ElementPopover.sass'

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24,
	},
}

export const ElementPopover = (props: any) => {
	const otherScenarios = filter(props.scenarios || [], (sc) => sc.id !== props.scenario?.id).concat([{ id: null }])

	return (
		<div className="ElementPopover">
			<div className="d-flex description">
				<small>{props.description}</small>
			</div>
			<div className="d-flex mb-2">
				<div className="title noselection">Status</div>

				<MiniSlider
					value={props.trend}
					key={props?.id + '-' + props.scenario?.id}
					onChange={(value) => props.onUpdate(props.id, { trend: '' + value })}
					className="highContrast"
					additionalMarkers={otherScenarios.map((sc) => {
						return {
							id: sc.id,
							name: sc.name || 'Baseline',
							value: getItemAlteredByScenario(props.originalElement, sc).trend,
						}
					})}
				></MiniSlider>
			</div>
			<div className="d-flex">
				<div className="title noselection">Weight</div>

				<Weight
					dark={true}
					onClick={(values: any) => {
						let newWeight = props.weight + 1
						if (newWeight > 3) newWeight = 0
						props.onUpdate(props.id, { weight: newWeight })
					}}
				>
					{props.weight}
				</Weight>
			</div>
		</div>
	)
}
