import gql from 'graphql-tag'
import { pick } from 'lodash'

export const cloneScenarioElements = (project, newScenario, referenceScenario, hooks) => {
	console.group('cloneScenarioElements')
	let impactedIndicators = []
	let impactedConstraints = []
	let impactedStakefactors = []

	// Baseline
	if (!referenceScenario) {
		console.log('Baseline')
		impactedIndicators = project.indicators
		impactedConstraints = project.constraints
		impactedStakefactors = project.stakefactors
	}
	// Scenario
	else {
		console.log('Scenario', referenceScenario)

		impactedIndicators = project.indicators.filter((i) => {
			let scenarios = i.scenarios ? JSON.parse(i.scenarios) : {}

			return !!scenarios[referenceScenario.id]
		})

		impactedConstraints = project.constraints.filter((i) => {
			let scenarios = i.scenarios ? JSON.parse(i.scenarios) : {}

			return !!scenarios[referenceScenario.id]
		})

		impactedStakefactors = project.stakefactors.filter((i) => {
			let scenarios = i.scenarios ? JSON.parse(i.scenarios) : {}

			return !!scenarios[referenceScenario.id]
		})
	}

	console.log('impacted elements', {
		impactedIndicators,
		impactedConstraints,
		impactedStakefactors,
	})

	// Indicators
	impactedIndicators.forEach((i) => {
		let newScenarios = {
			...(i.scenarios ? JSON.parse(i.scenarios) : {}),
		}
		if (referenceScenario) newScenarios[newScenario.id] = newScenarios[referenceScenario.id]
		else newScenarios[newScenario.id] = { ...pick(i, ['id', 'trend']) }

		hooks.updateIndicatorScenarios({
			variables: {
				id: i.id,
				scenarios: JSON.stringify(newScenarios),
			},
		})
	})

	// Constraints
	impactedConstraints.forEach((c) => {
		let newScenarios = {
			...(c.scenarios ? JSON.parse(c.scenarios) : {}),
		}
		if (referenceScenario) newScenarios[newScenario.id] = newScenarios[referenceScenario.id]
		else newScenarios[newScenario.id] = { ...pick(c, ['id', 'trend']) }

		hooks.updateConstraintScenarios({
			variables: {
				id: c.id,
				scenarios: JSON.stringify(newScenarios),
			},
		})
	})

	// Stakeholders
	impactedStakefactors.forEach((s) => {
		console.log(s, 'original scenario', s.scenarios)
		let newScenarios = {
			...(s.scenarios ? JSON.parse(s.scenarios) : {}),
		}
		if (referenceScenario) newScenarios[newScenario.id] = newScenarios[referenceScenario.id]
		else newScenarios[newScenario.id] = { ...pick(s, ['id', 'trend', 'impact', 'x', 'y']) }

		hooks.updateStakefactorScenarios({
			variables: {
				id: s.id,
				scenarios: JSON.stringify(newScenarios),
			},
		})

		console.log(s, 'new scenario', newScenarios)
	})

	console.groupEnd('cloneScenarioElements')
}

export const handleCreateScenario = async (projectId, context, referenceScenario, values: any, callback, hooks) => {
	let variables = {
		projectId: projectId,
		name: values.name,
		description: values.description || '',
	}

	let result = await hooks.createScenario({
		variables,
		update(cache, { data: { createScenario } }) {
			cache.modify({
				id: `Project:${projectId}`,
				fields: {
					scenarios(scenarios = []) {
						const newScenario = cache.writeFragment({
							id: 'Scenario:' + createScenario.id,
							data: createScenario,
							fragment: gql`
								fragment ScenarioFragment on Scenario {
									name
									projectId
									description
								}
							`,
						})

						return [...scenarios, newScenario]
					},
				},
			})
		},
		optimisticResponse: {
			createScenario: {
				id: 'temp-id',
				...variables,
			},
		},
	})

	cloneScenarioElements(context, result.data.createScenario, referenceScenario, hooks)
	callback()
}
